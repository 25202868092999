import { Component, OnInit } from '@angular/core';
import { faCircle, faMapMarkerAlt, faSlidersH, faStar } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SeleccionarPuntoEntregaComponent } from 'src/app/modal/seleccionar-punto-entrega/seleccionar-punto-entrega.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { FiltroDistribuidoresComponent } from 'src/app/modal/filtro-distribuidores/filtro-distribuidores.component';

@Component({
  selector: 'app-inicio-distribuidores-conectados',
  templateUrl: './inicio-distribuidores-conectados.component.html',
  styleUrls: ['./inicio-distribuidores-conectados.component.css'],
})
export class InicioDistribuidoresConectadosComponent implements OnInit {
  /** Íconos FontAwesome para la UI */
  faSlidersH = faSlidersH;
  faMapMarkerAlt = faMapMarkerAlt;
  faCircle = faCircle;
  faStar = faStar;

  /** Datos del punto de entrega seleccionado para filtrar distribuidores */
  punto_entrega: any;

  /** Datos de los distribuidores filtrados */
  distribuidores: any[] = [];

  /** Imagen producto placeholder */
  distribuidor_placeholder = '../../assets/img/icon-organizacion.png';

  /** Variables de filtrado */
  public ranking = 0;
  public tiempo_entrega = 'Selecciona';
  public categorias_estado: boolean[] = [];
  public todos_filtros_desactivados = true;

  /**Guarda los distribuidores sin filtrar
   * de tal forma que cuando se retiren o se cambien los filtros
   * no se tenga que hacer la petición a la base de datos */
  buffer_distribuidores: any;

  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
    private localStorage: LocalStorageService,
    private rest: RestService
  ) {}

  ngOnInit(): void {
    this.getSelectedPuntoEntrega();
    this.fetchCategorias();
  }

  /**
   * Trae el punto de entrega seleccionado, si lo hay
   * Si no hay ninguno seleccionado, le pide al usuario que escoja uno
   */
  async getSelectedPuntoEntrega() {
    if (this.auth.punto_seleccionado && this.auth.punto_seleccionado._id) {
      this.punto_entrega = this.auth.punto_seleccionado;
      this.fetchDistribuidores();
    } else {
      this.punto_entrega = await this.localStorage.getItem<any>('punto_entrega_seleccionado');
      if (this.punto_entrega && this.punto_entrega._id) {
        this.fetchDistribuidores();
      }
    }
  }

  /**
   * Lanza un modal para seleccionar el punto de entrega sobre el que se
   * van a gestionar las solicitudes
   */
  seleccionarPunto() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'modal-seleccionar-punto',
    };

    const modalRef = this.modalService.open(SeleccionarPuntoEntregaComponent, ngbModalOptions);
    modalRef.componentInstance.callback = () => {
      this.punto_entrega = this.auth.punto_seleccionado;
      this.localStorage.setItem<any>('punto_entrega_seleccionado', this.punto_entrega);
      this.fetchDistribuidores();
      this.fetchCategorias();
    };
  }

  /**
   * Trae la información de los distribuidores
   */
  async fetchDistribuidores() {
    this.distribuidores = [];
    if (this.punto_entrega != undefined) {
      const resp_dists: any = await this.rest
        .getJWT(`distribuidores_aprobados_por_punto/${this.punto_entrega._id}`)
        .toPromise();

      for (const aux of resp_dists.data) {
        if (aux.distribuidor != null) {
          aux['pedidos'] = 0;
          this.distribuidores.push(aux);
        }
      }
      //Array con los productos para las funcionalidades del filtro por categorias
      this.buffer_distribuidores = this.distribuidores;
      this.fetchPedidos();
    }
  }

  /**
   * Recorre la lista de distribuidores vinculados y averigua
   * cuántos pedidos se le ha hecho a cada uno
   */
  async fetchPedidos() {
    const obj = {
      punto_entrega: this.punto_entrega?._id,
      distribuidores: [] as any[],
    };
    let resp: any;
    let i = 0;
    for (const dist of this.distribuidores) {
      obj.distribuidores = [dist.distribuidor._id];
      resp = await this.rest.postJWT('pedidos_por_punto_dist_fechas', obj).toPromise();
      this.distribuidores[i].pedidos = resp.data.length;
      i++;
    }
  }

  /**
   * Llama al modal de filtrado
   */
  onFiltrar() {
    const modalRef = this.modalService.open(FiltroDistribuidoresComponent, {
      centered: true,
      windowClass: 'modal-filtro',
    });
    modalRef.componentInstance.ranking = this.ranking;
    modalRef.componentInstance.tiempo_entrega = this.tiempo_entrega;
    modalRef.componentInstance.todos_filtros_desactivados = this.todos_filtros_desactivados;
    modalRef.componentInstance.categorias_estado = this.categorias_estado;
    modalRef.componentInstance.distribuidores_componente_padre = this.buffer_distribuidores;
    modalRef.componentInstance.punto_entrega = this.punto_entrega;
    modalRef.componentInstance.callback = (
      rank: number,
      tiempos_entrega: string,
      todos_filtros_desactivados: boolean,
      categorias_estado: boolean[],
      distribuidores_componente_padre: any[]
    ) => {
      this.ranking = rank;
      this.tiempo_entrega = tiempos_entrega;
      this.todos_filtros_desactivados = todos_filtros_desactivados;
      this.categorias_estado = categorias_estado;
      this.distribuidores = distribuidores_componente_padre;
      console.log('this.ranking = rank;', this.ranking);
    };
  }

  /**
   * Trae los datos de las categorías de productos
   */
  async fetchCategorias() {
    const resp: any = await this.rest.getJWT('categoria_distribuidor').toPromise();
    this.categorias_estado = [];
    resp.data.forEach(() => {
      this.categorias_estado.push(false);
    });
  }
}
