import { Component, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import {
  faChevronRight,
  faMapMarkerAlt,
  faSearch,
  faStoreAlt,
  faTimesCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { EstablecimientosService } from '../../services/establecimientos/establecimientos.service';
import { DistribuidorService } from '../../services/distribuidor/distribuidor.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LocalDataSource } from 'ng2-smart-table';
import { PreAprobarClienteComponent } from 'src/app/modal/pre-aprobar-cliente/pre-aprobar-cliente.component';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { Router } from '@angular/router';
import { CargarClientesExcelComponent } from 'src/app/modal/cargar-clientes-excel/cargar-clientes-excel.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-clientes-info',
  templateUrl: './clientes-info.component.html',
  styleUrls: ['./clientes-info.component.css'],
})
export class ClientesInfoComponent implements OnInit {
  /** Referencias a íconos FontAwesome para la UI */
  faSearch = faSearch;
  faTimesCircle = faTimesCircle;
  faChevronRight = faChevronRight;
  faStoreAlt = faStoreAlt;
  faMapMarkerAlt = faMapMarkerAlt;
  faTrash = faTrash;
  /** Extraer el id del distribuidor logeado mediante localstorage */
  userInfo = JSON.parse(localStorage.getItem('auth') || '');
  idUserInfo = this.userInfo['user_distribuidor']['_id'];
  /** Nombre del cliente a buscar */
  nombre_cliente = '';
  /**Variable con datos del cliente */
  estado: any;
  idEstablecimiento: any;
  idUserHoreca: any;
  puntosEntrega: any;
  puntoEntrega: any;
  punto: any;
  nombreEstablecimiento: any;
  distribuidores: any;
  idPuntoEntrega: any;
  pedidos: any;
  trabajador: any;
  pedido: any;
  clientes: any;
  pedidoByPunto: any;
  tipo_dist_vinculado: any;
  /** Datos y configuracion de la tabla */
  public settings: any = {};
  public data: any = [];
  public source?: LocalDataSource;
  cantidadClientes: any = 0;
  cantidadMaxima: any = 0;

  constructor(
    private authService: AuthService,
    private establecimientosSerivce: EstablecimientosService,
    private distribuidorService: DistribuidorService,
    private clientService: ClientsService,
    private rest: RestService,
    private currency: CurrencyPipe,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.configSmartTable();
  }

  ngOnInit(): void {
    //this.getInfoDistribuidor();
    this.getDistribuidoresVinculados();
  }

  /** Trae el data de los distribuidores para vincular*/
  getDistribuidoresVinculados() {
    this.distribuidorService.getFilteredDistribuidoresVinculados(this.idUserInfo).subscribe((data) => {
      this.distribuidores = data;
      this.distribuidorService.getDistribuidorTipoEstablecimiento(this.idUserInfo).subscribe((data_2: any) => {
        let i = 0;
        //Se pasa el tipo de negocio en el campo de convenio solo
        //para mostrarlo en el DOWM dado que este campo no se utilizará
        data_2.forEach((element: any) => {
          this.distribuidores[i].convenio = element.tipo_negocio;
          i++;
        });
      });
      for (const item in this.distribuidores) {
        this.puntoEntrega = this.distribuidores[item].punto_entrega;
        this.establecimientosSerivce.getFilteredEstablecimientos(this.puntoEntrega.usuario_horeca).subscribe((res) => {
          this.nombreEstablecimiento = res.nombre_establecimiento;
        });
      }
    });
    this.loadData();
  }

  async getInfoDistribuidor() {
    const id = this.authService.user_distribuidor?._id || '';
    this.cantidadClientes = await this.distribuidorService.getClientsLength(id).toPromise();
    const cantidad = (await this.distribuidorService.getDistribuidorData(id).toPromise()).max_establecimientos;
    this.cantidadMaxima = cantidad || '+2000';
    // this.cantidadClientes = this.cantidadClientes > this.cantidadMaxima ? this.cantidadMaxima : this.cantidadClientes;
  }

  /**Lanza modal para preaprobar cliente */
  async onPreAprobar() {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      this.modalService.open(PreAprobarClienteComponent, {
        centered: true,
        windowClass: 'pre-aprobar-cliente',
      });
    }
  }

  /**
   * Configura la tabla
   */
  private configSmartTable(): void {
    this.settings = {
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
        custom: [
          {
            name: 'ver',
            title: 'Ver',
          },
          {
            name: 'delete',
            title: 'Cancelar',
          },
        ],
      },
      edit: {
        editButtonContent: 'Editar',
        saveButtonContent: 'Guardar',
        cancelButtonContent: 'Cancelar',
      },
      hideSubHeader: false,
      columns: {
        estado_vinculacion: {
          title: 'Estado de vinculación',
          editable: false,
          filter: true,
        },
        tipo_negocio: {
          title: 'Tipo de negocio',
          editable: false,
          filter: true,
        },
        establecimiento: {
          title: 'Establecimiento',
          editable: false,
          filter: true,
        },
        tipo_persona: {
          title: 'Tipo de persona',
          editable: false,
          filter: true,
        },
        nit: {
          title: 'NIT/CC/CE',
          editable: false,
          filter: true,
        },
        razon_social: {
          title: 'Razón social',
          editable: false,
          filter: true,
        },
        email: {
          title: 'Email (propietario)',
          editable: false,
          filter: true,
        },
        celular: {
          title: 'Teléfono (propietario)',
          editable: false,
          filter: true,
        },
        pais: {
          title: 'País',
          editable: false,
          filter: true,
        },
        departamento: {
          title: 'Departamento',
          editable: false,
          filter: true,
        },
        ciudad: {
          title: 'Ciudad',
          editable: false,
          filter: true,
        },
        punto_entrega: {
          title: 'Punto de entrega',
          editable: false,
          filter: true,
        },
        direccion: {
          title: 'Dirección',
          editable: false,
          filter: true,
        },
        num_sillas: {
          title: 'Número de sillas',
          editable: false,
          filter: true,
        },
        precio_especial: {
          title: 'Precio especial',
          editable: false,
          filter: true,
        },
        domicilio: {
          title: 'Domicilio',
          editable: false,
          filter: true,
        },
        estado_cartera: {
          title: 'Estado de cartera',
          editable: false,
          filter: true,
        },
        nombre_encargado: {
          title: 'Nombre del encargado del punto',
          editable: false,
          filter: true,
        },
        email_encargado: {
          title: 'Email del encargado',
          editable: false,
          filter: true,
        },
        cel_encargado: {
          title: 'Teléfono móvil del encargado',
          editable: false,
          filter: true,
        },
        equipo_asignado: {
          title: 'Equipo comercial asignado',
          editable: false,
          filter: true,
        },
        num_pedidos: {
          title: '#pedidos (últ. 3 meses)',
          editable: false,
          filter: true,
        },
        valor_pedidos: {
          title: 'Valor de pedidos (últ. 3 meses COP)',
          editable: false,
          filter: true,
        },
        num_productos: {
          title: '#productos (últ. 3 meses)',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Carga los datos a mostrar en la tabla, consumir el servicio para mostrar datos
   */
  private loadData() {
    this.clientService.getClienteByDistribuidor(this.idUserInfo).subscribe(async (res) => {
      console.log('res total',res)
      for (const aux of res) {
       
        if (aux.estado !== 'Pendiente') {
          /** Variables */
          let domicilios = '';
          let pazysalvo = '';
          let precio_especial = '';
          let totalSum = 0;
          let totalProduct = 0;
          /** Domicilios */
          if (aux.punto_entrega.domicilios === false) {
            domicilios = 'No';
          } else {
            domicilios = 'Si';
          }
          /** Paz y salvo */
          if (aux.pazysalvo === true) {
            pazysalvo = 'Paz y salvo';
          } else {
            pazysalvo = 'Cartera';
          }
          /** Convenio */
          if (aux.convenio === true) {
            precio_especial = 'Convenio';
          } else {
            precio_especial = 'No';
          }
          /** Trabajadores */
          if (aux.trabajador?.length == 0) {
            this.trabajador = 'No registra';
          }
          /** Total pedidos */
          for (const item of aux.pedido) {
            //console.log(aux.pedido[0].distribuidor._id + ' - ' + this.idUserInfo);
            if (aux.pedido[0].distribuidor._id === this.idUserInfo) {
              totalSum += item.total_pedido;
            }
          }
          /** Total productos */
          for (const j of aux.pedido) {
            totalProduct = j.productos.length;
          }
          /** Recupera el string con los nombres de los encargados */
          await this.rest
            .postJWT(`total_trabajadores_en_punto`, aux.vendedor)
            .toPromise()
            .then((nombres_encargados: any) => {
              /** Datos a cargar del punto para la tabla */
              this.data.push({
                id: aux._id,
                estado_vinculacion: aux.estado,
                tipo_negocio: aux.punto_entrega?.usuario_horeca?.tipo_negocio,
                establecimiento: aux.punto_entrega?.usuario_horeca?.nombre_establecimiento,
                tipo_persona: aux.punto_entrega?.usuario_horeca?.tipo_usuario,
                nit: aux.punto_entrega?.usuario_horeca?.nit,
                razon_social: aux.punto_entrega?.usuario_horeca?.razon_social,
                email: aux.punto_entrega?.usuario_horeca?.propietario_correo,
                celular: aux.punto_entrega?.usuario_horeca?.propietario_telefono,
                pais: aux.punto_entrega?.pais,
                departamento: aux.punto_entrega?.departamento,
                ciudad: aux.punto_entrega?.ciudad,
                punto_entrega: aux.punto_entrega?.nombre,
                direccion: aux.punto_entrega?.direccion,
                num_sillas: aux.punto_entrega?.sillas,
                precio_especial: precio_especial,
                domicilio: domicilios,
                estado_cartera: pazysalvo,
                nombre_encargado:
                  aux.punto_entrega?.informacion_contacto[0] + ' ' + aux.punto_entrega?.informacion_contacto[1],
                email_encargado: aux.punto_entrega?.informacion_contacto[2] || 'No registra',
                cel_encargado: aux.punto_entrega?.informacion_contacto[3] || 'No registra',
                equipo_asignado: nombres_encargados,
                num_pedidos: aux.pedido.length || 'No registra',
                valor_pedidos: totalSum || 'No registra',
                num_productos: totalProduct || 'No registra',
              });
            });
          //console.log(this.data);
          this.source = new LocalDataSource(this.data);
        }
      }
    });
  }

  public async onCustom(event: any): Promise<void> {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      if (event.action == 'ver') {
        this.router.navigate(['/detalle-cliente/' + event.data.id]);
      }
      if (event.action == 'delete') {
        const data_actualizada = {
          estado: 'Cancelado',
        };
        const ngbModalOptions: NgbModalOptions = {
          backdrop: 'static',
          keyboard: false,
          centered: true,
        };
        const modalRef = this.modalService.open(ConfirmacionComponent, ngbModalOptions);
        modalRef.componentInstance.msg = '¿Estás seguro de cancelar la vinculación con este punto de entrega?';
        modalRef.componentInstance.btn_msg_no = 'No, volver';
        modalRef.componentInstance.btn_msg_yes = 'Si, cancelar';
        modalRef.componentInstance.callback_no = () => {};
        modalRef.componentInstance.callback_yes = () => {
          this.clientService.cancelarVinculacionPuntoDistribuidor(event.data.id, data_actualizada).subscribe((res) => {
            window.location.reload();
          });
        };
      }
    }
  }

  public async verSolicitudes(): Promise<void> {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      this.router.navigate(['/clientes/solicitudes/']);
    }
  }
  async validatePermissions() {
    // eslint-disable-next-line prefer-const
    let blockUser = await this.authService.validatePermissionsUser();
    return blockUser;
  }
  /**
   * Lanza el modal que se encarga de recibir el archivo
   * de Excel con los clientes,
   * y toda la lógica necesaria para la creación de estos clientes preaprobados
   */
  async cargarClientesDesdeExcel() {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      const ngbModalOptions: NgbModalOptions = {
        //Evita que al hacer click por fuera se cierre el modal
        backdrop: 'static',
        keyboard: false,
        centered: true,
      };

      this.modalService.open(CargarClientesExcelComponent, ngbModalOptions);
    }
  }
}
