import { Component, DoCheck, OnInit, SecurityContext, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { RegistroComponent } from '../registro.component';
import { faUpload, faCheck, faTimes, faEye, faEyeSlash, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { RestService } from 'src/app/services/rest/rest.service';
import { UsuarioHoreca } from 'src/app/models/usuario-horeca.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { PlacesService } from 'src/app/services/places/places.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/tools/toast.service';

@Component({
  selector: 'app-registro-horeca',
  templateUrl: './registro-horeca.component.html',
  styleUrls: ['./registro-horeca.component.css'],
})
export class RegistroHorecaComponent implements OnInit, DoCheck {
  /** Iconos FontAwesome para usar en el template */
  faUpload = faUpload;
  faCheck = faCheck;
  faTimes = faTimes;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faChevronLeft = faChevronLeft;

  /**Imagen producto placeholder */
  public distribuidor_placeholder = '../../assets/img/icon-organizacion.png';

  /** Flag para mostrar u ocultar contraseña */
  fieldTextType = false;

  /** Arreglo con los formatos validos de imagen */
  supported_imgs: string[] = ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'];

  /** Variables para el manejo de la miniatura del logo */
  logo_path: SafeUrl | undefined;

  /** Para la búsqueda de empresas por NIT */
  empresas_registradas: any[] = [];
  empresa_encontrada: any;

  /** Variables para el manejo de la lista de departamentos y ciudades */
  public ciudades: any;
  public ciudades_empresa: any;
  public ciudades_trabajador: any;

  /**Guardara booleano para habilitar o no boton de continuar */
  public isBotonHabilitado = false;
  public isFormularioCompleto = false;

  /** Formularios reactivos */
  public horecaForm: FormGroup;
  public juridicoForm: FormGroup;
  public establecimientoForm: FormGroup;
  public establecimientoCredencialesForm: FormGroup;

  /**Booleano soy propietario */
  public soy_propietario = false;

  constructor(
    public registroComp: RegistroComponent,
    public router: Router,
    private restService: RestService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    public places: PlacesService,
    public toastService: ToastService,
    private formBuilder: FormBuilder
  ) {
    this.horecaForm = this.formBuilder.group({
      horecaTipo: ['', Validators.required],
      horecaCedula: ['', Validators.required],
      horecaNombre: ['', Validators.required],
      horecaApellido: ['', Validators.required],
      horecaPais: ['', Validators.required],
      horecaDepartamento: ['', Validators.required],
      horecaCiudad: ['', Validators.required],
      horecaCelular: ['', [Validators.required, Validators.pattern(/^3[\d]{9}$/)]],
      horecaTelefono: [''],
      horecaCorreo: ['', [Validators.required, Validators.email]],
      horecaContraseña: ['', Validators.required],
    });
    this.juridicoForm = this.formBuilder.group({
      juridicoNombreEstablecimiento: ['', Validators.required],
      juridicoEstablecimiento: ['', Validators.required],
      juridicoRazon: [''],
      juridicoNit: [''],
      juridicoPais: ['', Validators.required],
      juridicoDepartamento: ['', Validators.required],
      juridicoCiudad: ['', Validators.required],
      juridicoTelefono: ['', [Validators.required, Validators.pattern(/^(?=[0-9]*$)(?:.{7}|.{10})$/)]],
      juridicoTelefono2: [''],
      juridicoPropietarioTipo: ['', Validators.required],
      juridicoPropietarioCedula: ['', Validators.required],
      juridicoPropietarioNombre: ['', Validators.required],
      juridicoPropietarioApellido: ['', Validators.required],
      juridicoPropietarioTelefono: ['', [Validators.required, Validators.pattern(/^(?=[0-9]*$)(?:.{7}|.{10})$/)]],
      juridicoPropietarioCorreo: ['', [Validators.required, Validators.email]],
    });
    this.establecimientoForm = this.formBuilder.group({
      establecimientoTipo: ['', Validators.required],
      establecimientoCedula: ['', Validators.required],
      establecimientoNombre: ['', Validators.required],
      establecimientoApellido: ['', Validators.required],
      establecimientoPais: ['', Validators.required],
      establecimientoDepartamento: ['', Validators.required],
      establecimientoCiudad: ['', Validators.required],
      establecimientoEstablecimiento: ['', Validators.required],
      establecimientoCelular: ['', [Validators.required, Validators.pattern(/^3[\d]{9}$/)]],
      establecimientoTelefono: [''],
    });
    this.establecimientoCredencialesForm = this.formBuilder.group({
      establecimientoCorreo: ['', [Validators.required, Validators.email]],
      establecimientoContraseña: ['', Validators.required],
      establecimientoTerminos: ['', Validators.required],
    });
  }

  ngDoCheck(): void {
    this.verificaSiEmpresaExiste();
    this.habilitarBoton();
    this.comparaIDTrabajadorYPropietario();
  }

  async ngOnInit() {
    this.fieldTextType = false;
    //Se deben comentar las lineas 130 y 131, si se usa el API Imagine y descomentar 133, 134
    //this.places.getPlacesFromServer();
    //console.log('this.places', this.places);
    //**Obtiene departamentos y ciudades de Colombia com API Imagine Apps*/
    await this.places.getDepartmentFromServerImagine();
    this.getPlaces('nuevo');
    this.getUsuariosHoreca();
    this.verificaSiEmpresaExiste();
  }

  /**
   * Metodo para obtener departamentos y ciudades mediante API Imagine Apps
   */
  async getPlaces(tipo: string) {
    let id_dpto = 0;
    let consulta_departamento = '';
    if (tipo == 'horeca' || tipo == 'nuevo') {
      consulta_departamento = this.registroComp.departamento;
      for (const dpto of this.places.departments_colombia) {
        if (dpto.name == consulta_departamento) {
          break;
        }
        id_dpto++;
      }
      if (this.places.departments_colombia[id_dpto] != undefined) {
        await this.places.getCitiesFromServerImagine(this.places.departments_colombia[id_dpto].code);
        this.ciudades = this.places.cities_colombia;
      } else {
        this.ciudades = [];
      }
    } else if (tipo == 'juridico') {
      consulta_departamento = this.registroComp.departamento_empresa;
      for (const dpto of this.places.departments_colombia) {
        if (dpto.name == consulta_departamento) {
          break;
        }
        id_dpto++;
      }
      if (this.places.departments_colombia[id_dpto] != undefined) {
        await this.places.getCitiesFromServerImagine(this.places.departments_colombia[id_dpto].code);
        this.ciudades_empresa = this.places.cities_colombia;
      } else {
        this.ciudades_empresa = [];
      }
    } else if (tipo == 'trabajador') {
      consulta_departamento = this.registroComp.t_departamento;
      for (const dpto of this.places.departments_colombia) {
        if (dpto.name == consulta_departamento) {
          break;
        }
        id_dpto++;
      }
      if (this.places.departments_colombia[id_dpto] != undefined) {
        await this.places.getCitiesFromServerImagine(this.places.departments_colombia[id_dpto].code);
        this.ciudades_trabajador = this.places.cities_colombia;
      } else {
        this.ciudades_trabajador = [];
      }
    }
  }
  /**
   * Metodo para obtener departamentos y ciudades mediante API ServerJS
   */
  async getPlacesServer(dpto: any) {
    const consulta_departamento = dpto;
    this.registroComp.departamento;
    console.log('consulta_departamento', consulta_departamento);
    const resCiudades = [];
    for (const ciudad of this.places.places_colombia) {
      if (ciudad.departamento === consulta_departamento) {
        for (const ciu of ciudad.ciudades) {
          resCiudades.push(ciu);
        }
      }
    }
    console.log(resCiudades);
    this.ciudades = resCiudades;
    this.ciudades_empresa = resCiudades;
    this.ciudades_trabajador = resCiudades;
  }
  /**
   * Oculta o revela la contraseña al usuario
   */
  mostrarOcultarPassword() {
    this.fieldTextType = !this.fieldTextType;
  }

  /**
   * Recibe un archivo del usuario y lo guarda en el indice
   * correcto del arreglo de archivos a subir. Si el archivo
   * a subir no tiene el formato correcto, no se guarda y
   * se muestra un mensaje de error
   * @param event El evento generado al subir el archivo
   * @param index El indice del arreglo de archivos
   */
  handleFileInput(event: any, index: number) {
    this.registroComp.error_formato_pdf = false;
    this.registroComp.error_formato_icon = false;

    const file: File = event.target.files[0];
    if (file.size > 2000000) {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Este archivo supera el máximo de 2MB permitido!';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else if (file.name.slice(-4) != '.pdf' && index != 3) {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = '¡Solo archivos PDF están permitidos!';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else {
      if (file != null) {
        const file_split: string[] = file.name.split('.');
        const file_end: string = file_split[file_split.length - 1].trim().toLowerCase();
        if (index == 3) {
          //Se intenta subir un archivo de imagen
          if (this.supported_imgs.includes(file_end)) {
            this.registroComp.archivos[index] = file;
            this.logo_path =
              this.sanitizer.sanitize(
                SecurityContext.NONE,
                this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
              ) || '';
          } else {
            this.registroComp.error_formato_icon = true;
          }
        } else {
          //Se intenta subir un pdf
          if (file_end == 'pdf') {
            this.registroComp.archivos[index] = file;
          } else {
            this.registroComp.error_formato_pdf = true;
          }
        }
      } else {
        this.registroComp.archivos[index] = file;
        if (index == 3) {
          this.logo_path = undefined;
        }
      }
    }
  }

  /**
   * Para el registro del dueño de la empresa, llena automáticamente los datos diligenciados
   * en el paso 4 para el paso 5
   * Ocurre cuando el usuario hace click en "Yo soy el propietario"
   */
  duplicarDatosPropeitario(click: any) {
    this.registroComp.tipo_doc_identidad_prop = this.registroComp.tipo_doc_identidad;
    this.registroComp.doc_identidad_prop = this.registroComp.doc_identidad;
    this.registroComp.nombres_prop = this.registroComp.nombres;
    this.registroComp.apellidos_prop = this.registroComp.apellidos;
    this.registroComp.telefono_prop = this.registroComp.celular;
    this.registroComp.correo_prop = this.registroComp.correo;
  }

  /**
   * Para el registro del dueño de la empresa, llena automáticamente los datos diligenciados
   * en el paso 4 para el paso 5
   * Ocurre cuando el usuario hace click en "Yo soy el propietario - Natural"
   */
  borrarDuplicadoDatosPropeitario() {
    this.registroComp.tipo_doc_identidad_prop = '';
    this.registroComp.doc_identidad_prop = null;
    this.registroComp.nombres_prop = '';
    this.registroComp.apellidos_prop = '';
    this.registroComp.telefono_prop = null;
    this.registroComp.correo_prop = '';
    /** por asincronismo no se deshabilita el botón, se forza con este temporizador*/
    setTimeout(() => {
      this.isBotonHabilitado = false;
    }, 200);
  }

  /**
   * Valida si los documentos del trabajador y el propietario son iguales de serlo
   * duplica los datos del propietario, de no serlo garantiza que el flag quede false
   */
  comparaIDTrabajadorYPropietario() {
    /**Por conflicto con la ejecucion de funciones desde el HTML se establece este time out */
    setTimeout(() => {
      if (
        this.registroComp.doc_identidad_prop !== '' &&
        this.registroComp.doc_identidad === this.registroComp.doc_identidad_prop &&
        !this.soy_propietario
      ) {
        this.duplicarDatosPropeitario(true);
        this.soy_propietario = true;
      }
      /** Garantiza que si los documentos no son los mismos, el flag estará en false */
      if (this.registroComp.doc_identidad !== this.registroComp.doc_identidad_prop && this.soy_propietario) {
        this.soy_propietario = false;
      }
    }, 400);
  }

  /**
   * Recuperar la información de todas las empresas registradas en el sistema
   * */
  getUsuariosHoreca() {
    this.restService
      .get('usuario_horeca_reducidos')
      .toPromise()
      .then((resp: any) => {
        this.empresas_registradas = resp.data;
      })
      .catch(() => {
        const ngbModalOptions: NgbModalOptions = {
          //Evita que al hacer click por fuera se cierre el modal
          backdrop: 'static',
          keyboard: false,
        };
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'Ocurrió un error recuperando la lista de empresas registradas con nosotros. ¡Por favor intenta de nuevo más tarde!';
        modalRef.componentInstance.btn_msg = 'Volver a registro';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/login']);
        };
      });
  }

  /**Recibe el input del usuario y verifica si el valor es un nit o una cedula registrada
   * en caso lo sea devuelve el valor para continuar con el formulario
   * de no encontrarse, devuelve undefined y no se podrá habiltiar el boton de continuar
   */
  verificaSiEmpresaExiste() {
    if (this.registroComp.t_nit != '') {
      this.empresa_encontrada = this.getEmpresaPorNit(this.registroComp.t_nit);
      this.registroComp.t_horeca = this.empresa_encontrada;
    }
  }

  /**
   * Recibe un NIT por parámetro y devuelve el objeto UsuarioHoreca
   * que tiene ese mismo NIT, o undefined si ninguna empresa registrada
   * cumple con esto
   * @param nit El NIT a buscar
   * @returns El objeto UsuarioHoreca encontrado o undefined si no se encuentra ninguno
   */
  getEmpresaPorNit(nit: string) {
    let ans: UsuarioHoreca | undefined;
    this.empresas_registradas.forEach((emp_aux) => {
      if (emp_aux.nit == nit) {
        ans = emp_aux;
      }
    });
    return ans;
  }

  /**
   * Selecciona el tipo de perfil que está siendo registrado
   * @param perfil El tipo de perfil. Puede ser "Propietario
   * o representante legal" o "Trabajador"
   */
  seleccionarPerfil(perfil: string) {
    this.registroComp.selectPerfilHoreca(perfil);
  }

  /**
   * Selecciona el tipo de persona que está siendo registrado
   * @param tipo_persona El tipo de persona. Puede ser "Natural"
   * o "Jurídica"
   */
  seleccionarTipoPersona(tipo_persona_long: string) {
    const tipo_persona = tipo_persona_long.substring(1).substring(1).substring(1);
    this.registroComp.selectTipoPersona(tipo_persona);
  }

  /**
   * Metodos para mostrar un toast si existe un input invalido en el formulario
   */
  showDanger() {
    this.toastService.show('Tienes campos pendientes por revisar', { classname: 'bg-danger text-light', delay: 10000 });
  }

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  /**Este metodo tiene como objeto verificar si los formularios son invalidos
   * En caso de serlos mostrará un toast como alerta y marcara los campos errados*/
  checkIfFormIsInvalid() {
    if (this.router.url == '/registro/horeca') {
      //Propietario Natural o Jurídico
      if (this.registroComp.horeca_perfil_seleccionado == 'Propietario o representante legal') {
        if (this.registroComp.step == 4) {
          this.horecaForm.markAllAsTouched();
        }
        if (this.registroComp.step == 5) {
          this.juridicoForm.markAllAsTouched();
        }
      }
      //Trabajador
      if (this.registroComp.horeca_perfil_seleccionado == 'Trabajador') {
        if (this.registroComp.step == 4) {
          this.establecimientoForm.markAllAsTouched();
        }
        if (this.registroComp.step == 5) {
          this.establecimientoCredencialesForm.markAllAsTouched();
        }
      }
    }
    this.showDanger();
  }

  /**Esta funcion tiene como objeto deshabilitar o habilitar el boton de continuar
   * dependiendo si se han cumplido las condiciones
   * tales como un formulario valido o una seleccion requerida
   */
  habilitarBoton() {
    //Se inicializa siembre en boton deshabilitado, si se cumplen las condiciones se habilita
    this.isBotonHabilitado = false;
    this.isFormularioCompleto = false;
    /**------ Validaciones para Horeca ------ */
    if (this.router.url == '/registro/horeca') {
      // --------- Propietario ---------
      if (this.registroComp.horeca_perfil_seleccionado == 'Propietario o representante legal') {
        // ---- Paso 2 y 3 -----
        if (this.registroComp.step == 2 || this.registroComp.step == 3) {
          if (this.registroComp.tipo_persona == 'Natural' || this.registroComp.tipo_persona == 'Jurídica') {
            this.isBotonHabilitado = true;
          }
        }
        // ---- Paso 4 ----
        if (this.registroComp.step == 4) {
          /** Validación para campo opcional telfono, dado que es opcional no se valida con Validators
           * Si no cumple se cierra la función con Return, si cumple no entra al return y se habilita el botón.
           */
          if (
            this.registroComp.telefono != null &&
            this.registroComp.telefono != 0 &&
            this.registroComp.telefono.toString().length >= 1 &&
            this.registroComp.telefono.toString().length != 7 &&
            this.registroComp.telefono.toString().length != 10
          ) {
            return;
          } else {
            this.isBotonHabilitado = !this.horecaForm.invalid;
          }
        }
        // ---- Paso 5 ----
        if (this.registroComp.step == 5) {
          /** Validación para campo opcional telfono, dado que es opcional no se valida con Validators
           * Si no cumple se cierra la función con Return, si cumple no entra al return y se habilita el botón.
           */
          if (
            this.registroComp.telefono_empresa_2 != null &&
            this.registroComp.telefono_empresa_2 != 0 &&
            this.registroComp.telefono_empresa_2.toString().length >= 1 &&
            this.registroComp.telefono_empresa_2.toString().length != 7 &&
            this.registroComp.telefono_empresa_2.toString().length != 10
          ) {
            return;
          } else {
            if (this.registroComp.tipo_persona == 'Natural') {
              this.isBotonHabilitado = !this.juridicoForm.invalid;
            } else if (
              this.registroComp.tipo_persona == 'Jurídica' &&
              this.registroComp.razon_social != '' &&
              this.registroComp.nit != ''
            ) {
              this.isBotonHabilitado = !this.juridicoForm.invalid;
            }
          }
        }
        // ---- Paso 6 ----
        if (this.registroComp.step == 6) {
          // Validaciones basicas de documentos
          if (
            this.registroComp.acepto_terminos == true &&
            this.registroComp.archivos[0] != undefined &&
            this.registroComp.archivos[2] != undefined &&
            this.registroComp.archivos[3] != undefined
          ) {
            if (this.registroComp.tipo_persona == 'Natural') {
              // Persona natural no requiere camara de comercio
              this.isBotonHabilitado = true;
              this.isFormularioCompleto = true;
            } else if (
              //Jurdico si requiere camara de comercio
              this.registroComp.tipo_persona == 'Jurídica' &&
              this.registroComp.archivos[1] != undefined
            ) {
              this.isBotonHabilitado = true;
              this.isFormularioCompleto = true;
            }
          }
        }
      }
      // --------- Trabajador ---------
      if (this.registroComp.horeca_perfil_seleccionado == 'Trabajador') {
        // ---- Paso 2 -----
        if (this.registroComp.step == 2 || this.registroComp.step == 3) {
          /**Cuando se limpia el input queda con la información de la ultima busqueda
           * por esto forzamos con este primer if que al borrar el input se borren los datos*/
          if (this.registroComp.t_nit == '') {
            this.isBotonHabilitado = false;
          } else if (this.empresa_encontrada != undefined) {
            this.isBotonHabilitado = true;
          }
        }
        // ---- Paso 4 ----
        /**
         * Validación para campo opcional telfono, dado que es opcional no se valida con Validators
         * Si no cumple se cierra la función con Return, si cumple no entra al return y se habilita el botón.
         */
        if (
          this.registroComp.t_telefono != null &&
          this.registroComp.t_telefono.toString().length >= 1 &&
          this.registroComp.t_telefono.toString().length != 7 &&
          this.registroComp.t_telefono.toString().length != 10
        ) {
          this.isBotonHabilitado = false;
          return;
        } else {
          if (this.registroComp.step == 4) {
            this.isBotonHabilitado = !this.establecimientoForm.invalid;
          }
        }
        // ---- Paso 5 ----
        if (this.registroComp.step == 5 && this.registroComp.t_acepto_terminos == true) {
          this.isBotonHabilitado = !this.establecimientoCredencialesForm.invalid;
        }
      }
    }
  }

  /**
   * Permite retroceder un paso en el formulario de registro
   */
  stepBack() {
    if (this.registroComp.step > 1) {
      this.registroComp.step--;
      this.registroComp.continueHoreca();
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateOnlyText(event: any) {
    const keyCode = event.keyCode;
    if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   * */
  validateNumber(event: any) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }

  /**
   * Abre link en una nueva ventana
   */
  public verDocumento(link: any) {
    window.open('https://featapp.co/politicas', '_blank');
  }
}
