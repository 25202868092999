import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faMapMarkerAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CambiarCiudadComponent } from 'src/app/modal/cambiar-ciudad/cambiar-ciudad.component';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { Producto } from 'src/app/models/producto.model';
import { RestService } from 'src/app/services/rest/rest.service';
import { map, startWith } from 'rxjs/operators';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chequeo-precios-dist',
  templateUrl: './chequeo-precios-dist.component.html',
  styleUrls: ['./chequeo-precios-dist.component.css'],
})
export class ChequeoPreciosDistComponent implements OnInit {
  /** Íconos */
  public faMapMarkerAlt = faMapMarkerAlt;
  public faSearch = faSearch;
  /**Guardamos dinamicamente los valores de autocompletado */
  public filteredOptions: any;
  public mySearch = new FormControl();
  /** Búsqueda y datos */
  public productos: Producto[] = [];
  public producto_buscado: any;
  /**Imagen producto placeholder */
  public product_placeholder = '../../assets/img/product-placeholder.png';
  /** Ciudad y departamento seleccionado */
  public departamento: any;
  public ciudad: any;
  public ciudad_data_completa: any;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };

  constructor(private modalService: NgbModal, private rest: RestService, private router: Router) {}

  async ngOnInit() {
    if (this.ciudad === undefined) {
      this.changeCity();
    }
  }

  /**
   * Permite cambiar la ciudad donde se realiza la búsqueda
   */
  changeCity() {
    const modalRef = this.modalService.open(CambiarCiudadComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    modalRef.componentInstance.departamento = this.departamento;
    modalRef.componentInstance.ciudad = this.ciudad;
    modalRef.componentInstance.ciudades = this.ciudad_data_completa;
    modalRef.componentInstance.callback = (pDepto: any, pCiudad: any, pCiudadCompleta: any) => {
      this.departamento = pDepto;
      this.ciudad = pCiudad;
      this.ciudad_data_completa = pCiudadCompleta;
      this.producto_buscado = undefined;
      this.fetchProductosPorCiudad();
      this.autoCompletadoBusqueda();
    };
  }

  /**
   * Esta función tiene como objeto consultar todos los productos
   * disponibles de los distribuidores en la ciudad seleccionada
   * @param ciudad.codigo Codígo unico de la ciudad, se recibe en el back para buscar
   * el nombre de la ciudad y luego proceder a consultar los productos en la base de datos
   * @returns array de todos los productos disponibles en la ciudad,
   */
  fetchProductosPorCiudad() {
    /** Cargando generico */
    const modalLoading = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /** Consulta a enviar al back */
    const query = {
      ciudad: this.ciudad_data_completa.name,
    };
    /** Consulta a la base */
    this.rest
      .putJWT(`/chequeo_precios/`, query)
      .toPromise()
      .then((productos: any) => {
        /** Organiza los productos por nombre */
        this.productos = productos.sort((a: any, b: any) => {
          return a.nombre.localeCompare(b.nombre);
        });
        /** Se eliminan los objetos repetidos */
        this.productos = this.productos.filter(
          (value: any, index: any, self: any) => index === self.findIndex((t: any) => t.nombre === value.nombre)
        );
        modalLoading.close();
      })
      .catch((err: any) => {
        console.log(err);
        modalLoading.close();
        const modalRef = this.modalService.open(SimpleComponent, {
          centered: true,
          size: 'md',
        });
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'No fue posible recuperar los pedidos, intenta de nuevo mas tarde.';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/inicio-distribuidor']);
        };
      });
  }

  /**
   * Recupera el min y max precio de un producto dentro de los distribuidores de una ciudad
   * @param event Recibe el producto seleccionado en la barra de busqueda
   */
  async fetchProdsThatMatch(event: any) {
    /** Se captura la data del producto seleccionado en el desplegable */
    this.producto_buscado = event.option.value;
    /** Consulta a enviar al back */
    const query = {
      codigo_ft: this.producto_buscado.codigo_ft,
      ciudad: this.ciudad_data_completa.name,
    };
    /** Consulta a la base */
    this.rest
      .putJWT(`/chequeo_precios/`, query)
      .toPromise()
      .then((producto: any) => {
        this.producto_buscado = producto;
      })
      .catch((err: any) => {
        console.log(err);
        const modalRef = this.modalService.open(SimpleComponent, {
          centered: true,
          size: 'md',
        });
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'No fue posible recuperar los pedidos, intenta de nuevo mas tarde.';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/inicio-distribuidor']);
        };
      });
  }
  /************************** Busqueda autocompletado *************************/
  /**
   * Este metodo tiene como objetoautocompletar la busqueda del usuario
   */
  autoCompletadoBusqueda() {
    this.filteredOptions = this.mySearch.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.nombre)),
      map((nombre) => (nombre ? this._filter(nombre) : this.productos.slice()))
    );
  }
  displayFn(user: any): string | undefined {
    return user ? user.nombre : undefined;
  }

  returnFn(user: any): string | undefined {
    return user ? user.value : undefined;
  }

  itemDisplayFn(item: any) {
    return item ? item.name : '';
  }
  /**
   * Toma el string que entra por parámetro y cambia tildes y diéresis
   * por las letras sin acento, y lo pasa a minúsculas
   * @param pWord El string a filtrar
   * @returns El string filtrado
   */
  normalizeString(pWord: string): string {
    return pWord
      .trim()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase();
  }
  private _filter(nombre: string) {
    console.log('Nombre...', nombre);
    const filterValue = this.normalizeString(nombre.toLowerCase());
    console.log('filterValue', filterValue);
    return this.productos.filter((option: any) =>
      this.normalizeString(option.nombre).toLowerCase().includes(filterValue)
    );
  }
  private _filter2(nombre: string) {
    const filteredOptions = [];
    for (const producto of this.productos) {
      console.log('producto', producto);
      console.log('nombre', producto);

      if (producto.nombre.toLowerCase().includes(nombre)) {
        filteredOptions.push(producto);
      }
    }
    return filteredOptions;
  }
}
