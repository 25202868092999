import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, ElementRef, Input } from '@angular/core';
import videojs from 'video.js';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoComponent implements OnInit, OnDestroy {
  @ViewChild('target', { static: true }) target!: ElementRef;

  // Setting the video js options
  // which we need to use
  @Input() options:
    | {
        fluid: boolean;
        aspectRatio: string;
        autoplay: boolean;
        sources: {
          src: string;
          type: string;
        }[];
      }
    | undefined;
  player?: videojs.Player;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
      // console.log('onPlayerReady', this);
    });
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }
}
