import { Component, OnInit } from '@angular/core';
import { faChevronLeft, faCircle, faSearch, faFilter } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import { SeleccionarPuntoEntregaComponent } from 'src/app/modal/seleccionar-punto-entrega/seleccionar-punto-entrega.component';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { Distribuidor } from 'src/app/models/distribuidor.model';
import { RestService } from 'src/app/services/rest/rest.service';
import { Router } from '@angular/router';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FiltroDistribuidoresComponent } from 'src/app/modal/filtro-distribuidores/filtro-distribuidores.component';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-mis-distribuidores',
  templateUrl: './mis-distribuidores.component.html',
  styleUrls: ['./mis-distribuidores.component.css'],
})
export class MisDistribuidoresComponent implements OnInit {
  /** Punto de entrega seleccionado */
  public punto_entrega?: PuntoEntrega;

  /**Variable donde se guarda datos de todos los distribuidores vinculados */
  distribuidor: any;
  distVinculadosAprobados: any[] = [];
  filtro_dist_vinculados_punto: any;

  /**usuario horeca filtrado*/
  usuarioHoreca: any;

  /** Imagen producto placeholder */
  product_placeholder = '../../assets/img/product-placeholder.png';

  /** LocalStorage del punto de entrega */
  userInfo = JSON.parse(localStorage.getItem('auth') || '');
  idUserHoreca = this.userInfo['user_horeca']['_id'];
  // puntoEntregaSeleccionadoInfo: any = JSON.parse(localStorage.getItem('punto_entrega_seleccionado') || '');

  /** Referencias a íconos FontAwesome para la UI */
  faStar = faStar;
  faChevronLeft = faChevronLeft;
  faCircle = faCircle;
  faSearch = faSearch;
  faFilter = faFilter;

  /**Guarda los distribuidores sin filtrar*/
  bufferDistribuidor: any;

  /** Variables de filtrado */
  ranking = 0;
  tiempo_entrega = 'Selecciona';
  categorias: boolean[] = [true, false, false, false, false, false, false, false, false];

  /** Categorias filtro carousel*/
  todos = true;
  carnicos = false;
  lacteos = false;
  bebidas = false;
  frutas = false;
  maquinaria = false;
  licores = false;
  aseo = false;
  especializado = false;

  /**Guardamos dinamicamente los valores de autocompletado */
  filteredOptions: any;
  mySearch = new FormControl();
  searchTerm = '';

  constructor(
    private distribuidorService: DistribuidorService,
    private modalService: NgbModal,
    private rest: RestService,
    private auth: AuthService,
    private router: Router,
    public locallStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    if (this.auth.punto_seleccionado == undefined) {
      this.selecionarPunto();
    } else {
      this.punto_entrega = this.auth.punto_seleccionado;
    }
    this.fetchDistribuidores();
    this.autoCompletadoBusqueda();
  }

  async fetchDistribuidores() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    try {
      await this.rest
        .getJWT('distribuidor')
        .toPromise()
        .then((resp_1: any) => {
          /**Recupera todos los distribuidores*/
          this.distribuidorService.getDistribuidoresVinculadosPunto().subscribe((resp_2: any) => {
            const punto_entrega = JSON.parse(localStorage.getItem('punto_entrega_seleccionado') || '{}');
            /*Recupera los estados de vinculación de los distribuidores según el seleccionado punto de entrega */
            this.filtro_dist_vinculados_punto = resp_2.filter(
              (option: any) => option.punto_entrega == punto_entrega._id
            );
            /********** DISTRIBUIDORES ASOCIADOS AL PUNTO *********/
            this.distVinculadosAprobados = this.fetchDistribuidoresAsociadosPorEstado(
              resp_1,
              this.filtro_dist_vinculados_punto,
              'Aprobado'
            );
            /** Agrega la cantidad de pedidos de un distribuidor para un punto */
            for (const iterator of this.distVinculadosAprobados) {
              this.rest
                .getJWT(`pedido_punto_dist/${this.auth.punto_seleccionado?._id}/${iterator._id}`)
                .toPromise()
                .then((producto: any) => {
                  iterator.pedidos = producto?.pedidos?.length;
                });
            }
            /**Se guarda un buffer para la funcionalidad de filtrado */
            this.bufferDistribuidor = this.distVinculadosAprobados;
          });
        });
    } catch (error) {
      console.log(error);
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
        this.router.navigate(['/inicio']);
      };
    }
  }

  /**
   * Filtra los distribuidores asociados al punto segun su estado
   * @param todos_distribuidores Array de objetos con todos los distribuidores
   * @param array_distribuidores Array de objetos con los distribuidores del punto de entrega
   * @param estado String con el estado que se desea filtrar
   * @returns Array de objetos con los distribuidores del punto de entrega y con el estado solicitado
   */
  fetchDistribuidoresAsociadosPorEstado(todos_distribuidores: any, array_distribuidores: any, estado: any) {
    const my_dist_asociados_por_estado = array_distribuidores.filter((obj: any) => obj.estado == estado);
    return todos_distribuidores.filter((el: any) => {
      return my_dist_asociados_por_estado.some((f: any) => {
        if (f.distribuidor === null) {
          return false;
        }
        return f.distribuidor._id == el._id;
      });
    });
  }

  /**
   * Encuentra si un distribuidor está a paz y salvo
   * esta funcíon se ejecuta desde el DOM en el ngFor de tarjetas
   * @param1 ID del distribuidor
   * @returns Booleano si el distribuidor está a paz y salvo
   */
  getPazYSalvo(id: any) {
    const estatusPazYSalvo = this.filtro_dist_vinculados_punto.find((x: any) => x.distribuidor._id === id).pazysalvo;
    if (estatusPazYSalvo === true) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Lanza un modal para seleccionar el punto de entrega sobre el que se
   * van a gestionar las solicitudes
   */
  selecionarPunto() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'modal-selecionar-punto',
    };
    const modalRef = this.modalService.open(SeleccionarPuntoEntregaComponent, ngbModalOptions);
    modalRef.componentInstance.callback = () => {
      this.punto_entrega = this.auth.punto_seleccionado;
      this.locallStorage.setItem<any>('punto_entrega_seleccionado', this.punto_entrega);
      this.fetchDistribuidores();
    };
  }

  /********** Filtros **********/
  /**
   * Llama al modal de filtrado
   */
  onFiltrar() {
    const modalRef = this.modalService.open(FiltroDistribuidoresComponent, {
      centered: true,
      windowClass: 'modal-filtro',
    });
    modalRef.componentInstance.ranking = this.ranking;
    modalRef.componentInstance.tiempo_entrega = this.tiempo_entrega;
    modalRef.componentInstance.todos = this.categorias[0];
    modalRef.componentInstance.carnicos = this.categorias[1];
    modalRef.componentInstance.lacteos = this.categorias[2];
    modalRef.componentInstance.bebidas = this.categorias[3];
    modalRef.componentInstance.frutas = this.categorias[4];
    modalRef.componentInstance.maquinaria = this.categorias[5];
    modalRef.componentInstance.licores = this.categorias[6];
    modalRef.componentInstance.aseo = this.categorias[7];
    modalRef.componentInstance.especializado = this.categorias[8];
    modalRef.componentInstance.callback = (
      lista: Distribuidor[],
      rank: number,
      tiempos_entrega: string,
      categorias: boolean[]
    ) => {
      this.ranking = rank;
      this.tiempo_entrega = tiempos_entrega;
      this.categorias = categorias;

      //recuperamos la información completa de distribuidores
      this.distVinculadosAprobados = this.bufferDistribuidor;

      //metodos de filtrado a aplicar
      this.filtroRanking();
      this.filtroTiempoEntrega();
      this.filtroCategoria(false);
    };
  }

  /**
   * Este metodo tiene como objeto remover los filtros del carousel de categorias
   */
  filtrarTodos() {
    this.todos = true;
    this.carnicos = false;
    this.lacteos = false;
    this.bebidas = false;
    this.frutas = false;
    this.maquinaria = false;
    this.licores = false;
    this.aseo = false;
    this.especializado = false;
    this.distVinculadosAprobados = this.bufferDistribuidor;
  }

  /**
   * Este metodo tiene como objeto realizar el filtro de carousel de categorias
   */
  filtroCategoria(scroll: boolean) {
    /**
     * Si el filtro viene del boton de filtrar ejecuta esta asignación
     * si viene del filtro de scroll, no se debe realizar dado que afecta la funcionalidad
     * Esta asignación es para traer la data del modal del boton de filtro a este modulo
     */
    if (scroll == false) {
      this.todos = this.categorias[0];
      this.carnicos = this.categorias[1];
      this.lacteos = this.categorias[2];
      this.bebidas = this.categorias[3];
      this.frutas = this.categorias[4];
      this.maquinaria = this.categorias[5];
      this.licores = this.categorias[6];
      this.aseo = this.categorias[7];
      this.especializado = this.categorias[8];
    }
    this.distVinculadosAprobados = this.bufferDistribuidor;
    if (
      !this.carnicos &&
      !this.lacteos &&
      !this.bebidas &&
      !this.frutas &&
      !this.maquinaria &&
      !this.licores &&
      !this.aseo &&
      !this.especializado
    ) {
      this.distVinculadosAprobados = this.bufferDistribuidor;
      this.todos = true;
      return;
    } else {
      this.todos = false;
      this.distVinculadosAprobados = this.distVinculadosAprobados.filter(
        (obj) =>
          (this.carnicos && obj.tipo == 'Cárnicos') ||
          (this.lacteos && obj.tipo == 'Lácteos') ||
          (this.bebidas && obj.tipo == 'Bebidas') ||
          (this.frutas && obj.tipo == 'Frutas y verduras') ||
          (this.maquinaria && obj.tipo == 'Maquinaria e implementos') ||
          (this.licores && obj.tipo == 'Licores') ||
          (this.aseo && obj.tipo == 'Aseo y otros') ||
          (this.especializado && obj.tipo == 'Especializado - general')
      );
      /**
       * Se actualiza la informacion de categorias para que el modal y el filtro de scroll tengan la misma data
       */
      this.categorias[0] = this.todos;
      this.categorias[1] = this.carnicos;
      this.categorias[2] = this.lacteos;
      this.categorias[3] = this.bebidas;
      this.categorias[4] = this.frutas;
      this.categorias[5] = this.maquinaria;
      this.categorias[6] = this.licores;
      this.categorias[7] = this.aseo;
      this.categorias[8] = this.especializado;
    }
  }

  /**
   * Este metodo tiene como objeto realizar el filtro por ranking
   */
  filtroRanking() {
    if (this.ranking != 0) {
      this.distVinculadosAprobados = this.distVinculadosAprobados.filter((obj) => obj.ranking == this.ranking);
    }
  }

  /**
   * Este metodo tiene como objeto realizar el filtro por tiempo de entrega
   */
  filtroTiempoEntrega() {
    if (this.tiempo_entrega != 'Selecciona') {
      this.distVinculadosAprobados = this.distVinculadosAprobados.filter(
        (obj) => obj.tiempo_entrega == this.tiempo_entrega
      );
    }
  }

  /********** Buscador de autocompletado **********/
  /**
   * Este metodo tiene como objeto autocompletar la busqueda del usuario
   */
  autoCompletadoBusqueda() {
    this.filteredOptions = this.mySearch.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.nombre)),
      map((nombre) => (nombre ? this._filter(nombre) : this.distVinculadosAprobados.slice()))
    );
  }
  displayFn(user: any) {
    console.log(user ? user.value : '');
    return user ? user.nombre : undefined;
  }
  returnFn(user: any) {
    return user ? user.value : undefined;
  }
  itemDisplayFn(item: any) {
    return item ? item.name : '';
  }
  private _filter(nombre: string) {
    const filterValue = nombre.toLowerCase();
    return this.distVinculadosAprobados.filter((option: any) => option.nombre.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * Estos metodos tienen como objeto ver el detalle de los distribuidores
   * El primer metodo es para la lista completa de distribuidores
   * El segundo metodo es para los distribuidores aprobados
   */
  verDetalleDistribuidor(event: any) {
    const filtrado = event.option.value._id;
    this.router.navigate(['/distribuidores', filtrado]);
  }
}
