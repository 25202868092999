import { Component, OnInit } from '@angular/core';
import { faChevronLeft, faCircle, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest/rest.service';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-solicitudes-distribuidor',
  templateUrl: './solicitudes-distribuidor.component.html',
  styleUrls: ['./solicitudes-distribuidor.component.css'],
})
export class SolicitudesDistribuidorComponent implements OnInit {
  /** Iconos */
  faChevronLeft = faChevronLeft;
  faCircle = faCircle;
  faMapMarkerAlt = faMapMarkerAlt;
  faStar = faStar;

  /** Imagen producto placeholder */
  distribuidor_placeholder = '../../assets/img/icon-organizacion.png';

  /**Variable donde se guarda datos de todos los distribuidores vinculados */
  distVinculadosAprobados: any[] = [];
  distVinculadosPendientes: any[] = [];
  distVinculadosRechazados: any[] = [];

  /** Modal de carga para darle feedback al usuario */
  modalCarga?: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private distribuidorService: DistribuidorService,
    private rest: RestService
  ) {}

  ngOnInit(): void {
    this.fetchDistribuidores();
  }

  async fetchDistribuidores() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    try {
      await this.rest
        .getJWT('distribuidor')
        .toPromise()
        .then((resp_1: any) => {
          /**Recupera todos los distribuidores*/
          this.distribuidorService.getDistribuidoresVinculadosPunto().subscribe((resp_2: any) => {
            const punto_entrega = JSON.parse(localStorage.getItem('punto_entrega_seleccionado') || '{}');
            /*Recupera los estados de vinculación de los distribuidores según el seleccionado punto de entrega */
            const filtro_dist_vinculados_punto = resp_2.filter(
              (option: any) => option.punto_entrega == punto_entrega._id
            );
            console.log('filtro_dist_vinculados_punto', filtro_dist_vinculados_punto);
            /********** DISTRIBUIDORES ASOCIADOS AL PUNTO *********/
            this.distVinculadosAprobados = this.fetchDistribuidoresAsociadosPorEstado(
              resp_1,
              filtro_dist_vinculados_punto,
              'Aprobado'
            );
            this.distVinculadosPendientes = this.fetchDistribuidoresAsociadosPorEstado(
              resp_1,
              filtro_dist_vinculados_punto,
              'Pendiente'
            );
            this.distVinculadosRechazados = this.fetchDistribuidoresAsociadosPorEstado(
              resp_1,
              filtro_dist_vinculados_punto,
              'Rechazado'
            );
            console.log('this.distVinculadosRechazados', this.distVinculadosRechazados);
          });
          this.modalCarga?.close();
        });
    } catch (error) {
      console.log(error);
      this.modalCarga?.close();
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
        this.router.navigate(['/distribuidores']);
      };
    }
  }

  /**
   * Filtra los distribuidores asociados al punto segun su estado
   * @param todos_distribuidores Array de objetos con todos los distribuidores
   * @param array_distribuidores Array de objetos con los distribuidores del punto de entrega
   * @param estado String con el estado que se desea filtrar
   * @returns Array de objetos con los distribuidores del punto de entrega y con el estado solicitado
   */
  fetchDistribuidoresAsociadosPorEstado(todos_distribuidores: any, array_distribuidores: any, estado: any) {
    const my_dist_asociados_por_estado = array_distribuidores.filter((obj: any) => obj.estado == estado);
    console.log('my_dist_asociados_por_estado', my_dist_asociados_por_estado);
    return todos_distribuidores.filter((el: any) => {
      return my_dist_asociados_por_estado.some((f: any) => {
        console.log('f', f);
        if (f.distribuidor === null) {
          return false;
        }
        el.idOriginal = f._id;
        return f.distribuidor._id == el._id;
      });
    });
  }

  /**Este metodo tiene como objeto redireccionar al usuario al detalle del distribuidor seleccionado */
  volverSolicitud(event: any) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    const id = event.idOriginal;
    const update = { estado: 'Pendiente' };
    this.rest
      .put('distribuidores_vinculados/' + id, update)
      .toPromise()
      .then(() => {
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Genial!';
        modalRef.componentInstance.msg = 'Se ha envíado la solicitud con éxito';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          window.location.reload();
        };
      })
      .catch(() => {
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Esta solicitud no pudo ser actualizada. Intenta de nuevo más tarde';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {};
      });
  }
  /**Este metodo tiene como objeto redireccionar al usuario al detalle del distribuidor seleccionado */
  verDetalleDistribuidor(event: any) {
    const filtrado = event.distribuidor._id;
    this.router.navigate(['/distribuidores', filtrado]);
  }
}
