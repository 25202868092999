import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-puntos-card',
  templateUrl: './puntos-card.component.html',
  styleUrls: ['./puntos-card.component.css'],
})
export class PuntosCardComponent implements OnInit {
  @Input() punto: any;

  constructor() {}

  ngOnInit(): void {}

  openDetail() {
    if (this.punto) {
      console.log('punto....')
      window.location.href = `/detalle-cliente/${this.punto._id}`;
    }
  }
}
