import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalDataSource } from 'ng2-smart-table';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { PedidosDistribuidorComponent } from '../pedidos-distribuidor.component';

@Component({
  selector: 'app-pedidos-info',
  templateUrl: './pedidos-info.component.html',
  styleUrls: ['./pedidos-info.component.css'],
})
export class PedidosInfoComponent implements OnInit {
  /**Variable para envío de la lista de pedidos a componente padre - función imprimir a xlxs */
  @Output() setListaInfo = new EventEmitter<any>();
  /** Variable de control para la navegación del panel */
  active = 1;
  /** Datos y configuración de la tabla */
  public settings: any = {};
  public data: any = [];
  public source?: LocalDataSource;
  public todos_pedidos: any[] = [];
  /** Guarda los datos de los puntos vinculados */
  public clientes_vinculados_distribuidor: any;
  /** Indicadores de los pedidos */
  pendientes = 0;
  aprobados = 0;
  alistando = 0;
  despachados = 0;
  entregados = 0;
  cancelados = 0;
  /** Referencia al modal de carga */
  modalCarga?: NgbModalRef;

  constructor(
    private rest: RestService,
    private auth: AuthService,
    private clientService: ClientsService,
    private currency: CurrencyPipe,
    private modalService: NgbModal,
    private pedidosDistribuidorComponent: PedidosDistribuidorComponent,
    private router: Router
  ) {}

  async ngOnInit() {
    this.clientes_vinculados_distribuidor = await this.clientService
      .getClienteByDistribuidor(this.auth.user_distribuidor?._id)
      .toPromise();
    this.configSmartTable();
    this.getPedidos();
  }

  /**
   * Recupera todos los pedidos del usuario
   */
  getPedidos() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    this.rest
      .getJWT(`pedidos/detalle_pedidos_distribuidor/${this.auth.user_distribuidor?._id}`)
      .toPromise()
      .then((resp: any) => {

        this.todos_pedidos = resp;
        this.loadData();
        this.modalCarga?.close();
      })
      .catch((err) => {
        this.modalCarga?.close();
        const modalRef = this.modalService.open(SimpleComponent, {
          centered: true,
          size: 'md',
        });
        if (err.status && err.status == 401) {
          this.auth.signOut();
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg = 'Tu sesión ha expirado. Por favor inicia sesión de nuevo para continuar.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            this.router.navigate(['/login']);
          };
          return;
        }
        this.modalCarga?.close();
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'Ocurrió un error cargando la información de los pedidos. Intenta de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigateByUrl('/inicio-distribuidor');
        };
      });
  }

  /**
   * Configura la tabla
   */
  private configSmartTable(): void {
    this.settings = {
      pager: {
        display: true,
        perPage: 15,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        accion: {
          title: 'Acción',
          editable: false,
          filter: false,
          type: 'custom',
          renderComponent: BtnEditarPedidoDist,
        },
        id_pedido: {
          title: 'ID pedido',
          editable: false,
          filter: true,
        },
        estado_pedido: {
          title: 'Estado del pedido',
          editable: false,
          filter: true,
        },
        valor_pedido: {
          title: 'Valor del pedido (COP)',
          editable: false,
          filter: true,
        },
        metodo_pago: {
          title: 'Método de pago',
          editable: false,
          filter: true,
        },
        establecimiento: {
          title: 'Establecimiento',
          editable: false,
          filter: true,
        },
        punto_entrega: {
          title: 'Punto de Entrega',
          editable: false,
          filter: true,
        },
        tipo_usuario: {
          title: 'Tipo de persona',
          editable: false,
          filter: true,
        },
        nit: {
          title: 'NIT/CC/CE',
          editable: false,
          filter: true,
        },
        pais: {
          title: 'País',
          editable: false,
          filter: true,
        },
        departamento: {
          title: 'Departamento',
          editable: false,
          filter: true,
        },
        ciudad: {
          title: 'Ciudad',
          editable: false,
          filter: true,
        },
        tipo_negocio: {
          title: 'Tipo de negocio',
          editable: false,
          filter: true,
        },
        fecha_solicitud_pedido: {
          title: 'Fecha de solicitud de pedido',
          editable: false,
          filter: true,
        },
        hora_aprobacion: {
          title: 'Hora de aprobación',
          editable: false,
          filter: true,
        },
        fecha_entrega_pedido: {
          title: 'Fecha de entrega de pedido',
          editable: false,
          filter: true,
        },
        hora_entregado: {
          title: 'Hora de entregado',
          editable: false,
          filter: true,
        },
        numero_productos: {
          title: 'Número de productos',
          editable: false,
          filter: true,
        },
        puntos: {
          title: 'Puntos redimidos',
          editable: false,
          filter: true,
        },
        codigo_usado: {
          title: 'Código usado',
          editable: false,
          filter: true,
        },
        estado_redencion: {
          title: 'Estado de redención del código',
          editable: false,
          filter: true,
        },
        equipo_comercial: {
          title: 'Equipo comercial asignado',
          editable: false,
          filter: true,
        },
      },
      noDataMessage: 'Sin información',
    };
  }

  /**
   * Carga los datos a mostrar en la tabla
   */
  async loadData() {
    /** Carga la información en la tabla */
    let resp_cods = [];
    let cods_str = '';
    let cods_estados = '';
    for (const aux of this.todos_pedidos) {
      //Información de los códigos usados en el pedido
      resp_cods = this.processCodes(aux);
      cods_str = resp_cods[0];
      cods_estados = resp_cods[1];
      //Información del estado del pedido
      await this.processState(aux, cods_str, cods_estados);
    }
  }

  /**
   * Hace un check para verificar que el pedido se puede tener en cuenta
   * en la tabla según su estado (se excluye únicamente si tiene estado
   * 'Pendiente' o 'Sugerido'). Si sí cumple, se agrega al arreglo de data
   * y se actualiza el source de la tabla
   */
  async processState(pedido: any, cods_str: string, cods_estados: string) {
    /** Arma los nombres del equipo comercial */
    let nombres_equipo_comercial = '';
    if (pedido.equipo_comercial.length > 0) {
      pedido.equipo_comercial[0].forEach((trabajador: any) => {
        nombres_equipo_comercial =
          nombres_equipo_comercial + ' ' + trabajador.nombres + ' ' + trabajador.apellidos + '  | ';
      });
      nombres_equipo_comercial = nombres_equipo_comercial.slice(0, -2);
    }

    /** Se arma objeto para la tabla */
    const obj_pedido = {
      accion: {
        id_pedido: pedido._id,
      },
      id_pedido: pedido.idPedido,
      estado_pedido: this.procesarEstado(pedido.estado),
      valor_pedido: pedido.valor_pedido,
      establecimiento: pedido.establecimiento[0] || '',
      punto_entrega: pedido.punto_entrega[0] || '',
      tipo_usuario: pedido.tipo_usuario[0] || '',
      nit: pedido.nit[0] || '',
      pais: pedido.pais[0] || '',
      departamento: pedido.departamento[0] || '',
      ciudad: pedido.ciudad[0] || '',
      tipo_negocio: pedido.tipo_negocio[0] || '',
      fecha_solicitud_pedido: pedido.fecha.split('T')[0] || 'No registra',
      hora_aprobacion: pedido.tracking_aprobado_externo || '',
      fecha_entrega_pedido: pedido.tracking_entregado[1].split('T')[0] || '',
      hora_entregado: pedido.tracking_entregado[0] || '',
      numero_productos: pedido.numero_productos || '0',
      puntos: pedido.puntos_redimidos || '0',
      codigo_usado: cods_str,
      estado_redencion: cods_estados,
      equipo_comercial: nombres_equipo_comercial,
      metodo_pago: pedido.metodo_pago,
    };
    if (pedido.estado != 'Sugerido') {
      this.data.push(obj_pedido);
      this.pedidosDistribuidorComponent.data_excel = this.data;
      this.source = new LocalDataSource(this.data);
      this.generarEvento();
    }
  }

  /**
   * Permite procesar los códigos de descuento de un pedido
   * y sus estados. Devuelve un arreglo donde la primera
   * posición tiene un string con los códigos usados y la
   * segunda posición tiene un string con los estados
   * correspondientes a los códigos usados
   */
  processCodes(aux: any) {
    //Información de los códigos de descuento
    let cods_str = '';
    let cods_estados = '';
    let i = 0;
    for (const cod_aux of aux.codigo_descuento) {
      cods_str += cod_aux.codigo_creado;
      cods_estados += cod_aux.estado;
      if (i != aux.codigo_descuento.length - 1) {
        cods_str += ', ';
        cods_estados += ', ';
      }
      i++;
    }
    if (cods_str == '') {
      cods_str = 'No registra';
    }
    if (cods_estados == '') {
      cods_estados = 'No registra';
    }
    return [cods_str, cods_estados];
  }

  /** Función que genera el evento para paso de información del listado de pedidos a componente padre */
  generarEvento() {
    this.setListaInfo.emit(JSON.parse(JSON.stringify(this.source)));
  }

  /**
   * Toma el estado actual del pedido en su forma cruda
   * y asigna un equivalente que tenga sentido para el
   * distribuidor
   * @param estado_crudo El estado a traducir
   * @return Un string con el estado equivalente para el distribuidor
   */
  procesarEstado(estado_crudo: string): string {
    let estado_traducido = estado_crudo;

    switch (estado_crudo) {
      case 'Pendiente':
        estado_traducido = 'Pendiente';
        this.pendientes++;
        break;
      case 'Sugerido':
        estado_traducido = 'Sugerido';
        this.pendientes++;
        break;
      case 'Aprobado Interno':
        estado_traducido = 'Pendiente';
        this.pendientes++;
        break;
      case 'Aprobado Externo':
        estado_traducido = 'Aprobado';
        this.aprobados++;
        break;
      case 'Alistamiento':
        estado_traducido = 'Alistamiento';
        this.alistando++;
        break;
      case 'Despachado':
        estado_traducido = 'Despachado';
        this.despachados++;
        break;
      case 'Facturado':
        estado_traducido = 'Facturado';
        this.entregados++;
        break;
      case 'Entregado':
        estado_traducido = 'Entregado';
        this.entregados++;
        break;
      case 'Recibido':
        estado_traducido = 'Recibido';
        this.entregados++;
        break;
      case 'Calificado':
        estado_traducido = 'Calificado';
        this.entregados++;
        break;
      case 'Cancelado por horeca':
        estado_traducido = 'Cancelado por cliente';
        this.cancelados++;
        break;
      case 'Cancelado por distribuidor':
        estado_traducido = 'Cancelado por distribuidor';
        this.cancelados++;
        break;
      case 'Rechazado':
        estado_traducido = 'Rechazado';
        this.cancelados++;
        break;
    }
    return estado_traducido;
  }

  filtrarPorEstado(filtro: string) {
    /** Se limpia la tabla para volver a cargar información nueva */
    this.source = new LocalDataSource(this.data);
    /** Filtra los pedidos según selección en navpills */
    if (filtro !== 'todos') {
      let filter: any[] = [];
      const estados = {
        pendientes: ['Aprobado Interno', 'Pendiente'],
        aprobados: ['Aprobado Externo', 'Aprobado'],
        alistando: ['Alistamiento'],
        despachados: ['Despachado'],
        entregados: ['Entregado', 'Recibido', 'Facturado', 'Calificado'],
        cancelados: ['Cancelado por cliente', 'Cancelado por distribuidor', 'Rechazado'], //No habilitado
      };
      if (filtro === 'pendientes') {
        filter = estados.pendientes;
      } else if (filtro === 'aprobados') {
        filter = estados.aprobados;
      } else if (filtro === 'alistando') {
        filter = estados.alistando;
      } else if (filtro === 'despachados') {
        filter = estados.despachados;
      } else if (filtro === 'entregados') {
        filter = estados.entregados;
      } else if (filtro === 'cancelados') {
        filter = estados.cancelados;
      }
      const pedidos_filtrados = this.data.filter((el: any) => {
        return filter.some((f: string) => {
          return el.estado_pedido === f;
        });
      });
      this.source = new LocalDataSource(pedidos_filtrados);
    } else {
      this.source = new LocalDataSource(this.data);
    }
  }
}

@Component({
  selector: 'app-btn-editar-pedido-dist',
  template: `
    <button class="btn-purple" (click)="openDetallePedido()">Ver</button>
  `,
  styles: [
    `
      .btn-purple {
        width: 100%;
        padding: 0px;
        background-color: transparent;
        border: transparent;
        color: #8e6ff7;
      }
    `,
  ],
})
export class BtnEditarPedidoDist {
  /** Datos del producto a editar */
  @Input() value?: { id_pedido: string };

  constructor(private router: Router) {}

  /**
   * Toma el id que entró por parámetro a este componente y abre el componente
   * del detalle de pedido de distribuidor usando el id como parámetro de la ruta
   */
  public openDetallePedido() {
    this.router.navigate([`/pedidos-distribuidor/${this.value?.id_pedido}`]);
  }
}
