import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PortafolioDistConveniosComponent } from 'src/app/modal/portafolio-dist-convenios/portafolio-dist-convenios.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';

@Component({
  selector: 'app-info-adicional-cliente',
  templateUrl: './info-adicional-cliente.component.html',
  styleUrls: ['./info-adicional-cliente.component.css'],
})
export class InfoAdicionalClienteComponent implements OnInit {
  faTimes = faTimes;
  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };
  public vendedorTmp: any = '';
  public vendedoresCliente: any[] = [];
  public vendedores: any[] = [];
  public tieneConvenio = '';
  public tieneCartera = '';
  @Input() distribuidorVinculado: any;
  @Output() onVendedoresChange = new EventEmitter<string[]>();
  @Output() onInfoAdicionalChange = new EventEmitter<any>();
  public opcionesPrecios = [
    { value: true, label: 'Tiene convenio' },
    { value: false, label: 'Sin convenio' },
  ];
  public opcionesCartera = [
    { value: true, label: 'En cartera' },
    { value: false, label: 'Paz y salvo' },
  ];
  selectProducts: any;

  constructor(private distribuidorService: DistribuidorService,     
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    console.log('this.distribuidorVinculado', this.distribuidorVinculado);
    this.getVendedores();
    if (this.distribuidorVinculado.estado == 'Pendiente') {
      this.tieneCartera = '';
      this.tieneConvenio = '';
    } else {
      this.tieneCartera = this.distribuidorVinculado.cartera;
      this.tieneConvenio = this.distribuidorVinculado.convenio;
      this.selectProducts = this.tieneConvenio ;
    }
  }

  /**
   * Trae los vendedores disponibles del distribuidor
   */
  getVendedores() {
    this.distribuidorService
      .getVendedoresByDistribuidor(this.distribuidorVinculado.distribuidor)
      .subscribe((data: any) => {
        this.vendedores = data;
        if (this.vendedores?.length > 0) {
          this.vendedoresCliente = this.vendedores.filter((element) => {
            console.log('element', element._id, this.distribuidorVinculado.vendedor);
            return this.distribuidorVinculado?.vendedor?.includes(element._id);
          });
          console.log(' this.vendedoresCliente', this.vendedoresCliente);
        }
        this.vendedores = this.vendedores.filter((element) => {
          return !this.distribuidorVinculado?.vendedor?.includes(element._id);
        });
      });
  }
  verProductos(){
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'xl',
      centered: true,
    };
    const modalRef = this.modalService.open(PortafolioDistConveniosComponent, ngbModalOptions);
    modalRef.componentInstance.msg = '¿Estás seguro de rechazar la vinculación con este punto de entrega?';
    modalRef.componentInstance.btn_msg_no = 'Volver';
    modalRef.componentInstance.btn_msg_yes = 'Guardar';
    modalRef.componentInstance.distribuidor = this.distribuidorVinculado.distribuidor;
    modalRef.componentInstance.idPunto = this.distribuidorVinculado.punto_entrega._id
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      const modalRef = this.modalService.open(SimpleComponent, this.modalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
      modalRef.componentInstance.title = '¡Genial!';
      modalRef.componentInstance.msg = 'Se han cargado los productos con descuentos especiales.';
      modalRef.componentInstance.btn_msg = 'Listo';
      modalRef.componentInstance.close_callback = () => {
      };
    };
  }
  asignarVendedor() {
    if (this.vendedorTmp == '') {
      window.alert('Seleccione a un vendedor');
      return;
    }
    const vendedor = this.vendedores.filter((element) => element._id === this.vendedorTmp)[0];
    this.vendedoresCliente.push(vendedor);
    this.vendedores.splice(this.vendedores.indexOf(vendedor), 1);
    this.vendedorTmp = '';
    this.onVendedoresChange.emit(this.vendedoresCliente.map((element) => element._id));
  }

  eliminarVendedor(vendedor: any) {
    this.vendedores.push(vendedor);
    this.vendedoresCliente.splice(this.vendedoresCliente.indexOf(vendedor), 1);
    this.vendedorTmp = '';
    this.onVendedoresChange.emit(this.vendedoresCliente.map((element) => element._id));
  }

  changeConvenio(value: any) {
    console.log(value)
    if(value === 'true'){
      this.selectProducts = true;
    } else {
      this.selectProducts = false;
    }
    this.tieneConvenio = value;
    this.emitInfoChange();
  }

  changeCartera(value: any) {
    this.tieneCartera = value;
    this.emitInfoChange();
  }

  emitInfoChange() {
    this.onInfoAdicionalChange.emit({
      convenio: this.tieneConvenio,
      cartera: this.tieneCartera,
    });
  }
}
