import { AgmMap, MapsAPILoader } from '@agm/core';
import { Options } from '@angular-slider/ngx-slider';
import {
  Component,
  DoCheck,
  OnInit,
  SecurityContext,
  ViewChild,
  TemplateRef,
  NgZone,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  faCheck,
  faDollarSign,
  faEye,
  faEyeSlash,
  faSearch,
  faTimes,
  faUpload,
  faChevronLeft,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { MapService } from 'src/app/services/map/map.service';
import { PlacesService } from 'src/app/services/places/places.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { RegistroComponent } from '../registro.component';
import { ToastService } from 'src/app/services/tools/toast.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';

declare const google: any;

@Component({
  selector: 'app-registro-distribuidor',
  templateUrl: './registro-distribuidor.component.html',
  styleUrls: ['./registro-distribuidor.component.scss'],
})
export class RegistroDistribuidorComponent implements OnInit, DoCheck, AfterViewInit {
  /** Iconos FontAwesome para usar en el template */
  faUpload = faUpload;
  faCheck = faCheck;
  faTimes = faTimes;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faSearch = faSearch;
  faDollarSign = faDollarSign;
  faChevronLeft = faChevronLeft;
  faTimesCircle = faTimesCircle;

  /**Imagen producto placeholder */
  public distribuidor_placeholder = '../../assets/img/icon-organizacion.png';

  /** Variables de control para los sliders de los horarios de atención */
  minValueEntreSemana = 8;
  maxValueEntreSemana = 17;
  minValueSabados = 8;
  maxValueSabados = 17;
  minValueDomingos = 8;
  maxValueDomingos = 17;
  options: Options = {
    floor: 0,
    ceil: 24,
    step: 0.5,
    showTicks: false,
    translate: (): string => {
      return '';
    },
  };

  /** Arreglo con los formatos validos de imagen */
  supported_imgs: string[] = ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'];

  /** Variables para el manejo de la miniatura del logo */
  logo_path: SafeUrl | undefined;

  /** Variables para el manejo de la lista de departamentos y ciudades */
  id_dpto = 0;
  id_dpto_empresa = 0;
  id_dpto_trab = 0;
  departamentos: any;
  ciudades: any;
  ciudades_empresa: any;
  ciudades_trab: any;

  //Lista de distribuidores registrados
  distribuidores: { _id: string; nit_cc: string; nombre: string; logo: string }[] = [];

  /** Flag para mostrar u ocultar contraseña */
  fieldTextType = false;

  /**Booleano soy propietario */
  public soy_propietario = false;

  /** Para la búsqueda de empresas por NIT */
  public empresas_registradas: any[] = [];
  public empresa_encontrada: any;

  /**Guardara booleano para habilitar o no boton de continuar */
  public isBotonHabilitado = false;
  public isFormularioCompleto = false;

  /** Guarda booleano que verifica si al menos un día de atención fue seleccionado */
  public validatorDiasAtencionError = true;
  public validatorTiempoEntregaError = true;

  public listaColores: any = [];

  /** Info multi poligono */
  polygonsList: any = [];
  valoresPedido: any = [];
  coordMulti: any = [];
  public paths = [
    { lat: 25.774, lng: -80.19 },
    { lat: 18.466, lng: -66.118 },
    { lat: 32.321, lng: -64.757 },
  ];

  listaHoras: any = Array(48)
    .fill(1)
    .map((x, i) => i + 1);
  listaDias: any = Array(30)
    .fill(1)
    .map((x, i) => i + 1);
  diasSemana: any = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  referenciaMapa: any;
  coordenadasPuntos: any = [];

  /******************* Datos para el manejo del mapa *******************/
  @ViewChild('AgmMap') agmMap!: AgmMap;
  @ViewChild('AgmMapNat') agmMapNat!: AgmMap;
  @ViewChild('AgmMapArea') agmMapArea!: AgmMap;
  @ViewChild('direccionRegistro') direccionRegistro!: ElementRef;
  public map: any = { lat: 4.678508639544325, lng: -74.05550588007192 };
  public mapPoligono: any = { lat: 4.678508639544325, lng: -74.05550588007192 };
  public drawingManager: any;
  public selectedShape: any;
  public selectedArea = 0;
  public drawing_map: any;
  /** Decide que mapa mostrar (solo visualización o editable) */
  mapa_en_blanco = true;
  /** Guarda las coordenadas del poligono de cobertura */
  nueva_cobertura: any;
  poligono_final: { longitud: number; latitud: number }[] = [];
  /** Formularios reactivos */
  public distribuidorForm: FormGroup;
  public juridicoForm: FormGroup;
  public establecimientoForm: FormGroup;
  public establecimientoCredencialesForm: FormGroup;
  public establecimientoTiempoEntregaForm: FormGroup;
  public establecimientoCoberturaForm: FormGroup;

  public departamentosPoligono: any;
  public departamentoPoligono: any;
  public ciudadPoligono: any;
  public ciudadesPoligono: any;

  constructor(
    public registroComp: RegistroComponent,
    public router: Router,
    private restService: RestService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    public places: PlacesService,
    private mapService: MapService,
    public toastService: ToastService,
    private currencyPipe: CurrencyPipe,
    private formBuilder: FormBuilder,
    private zone: NgZone,
    private mapsAPILoader: MapsAPILoader
  ) {
    /***************** REACTIVE FORMS - VALIDATORS *****************/
    this.distribuidorForm = this.formBuilder.group({
      distribuidorTipo: ['', Validators.required],
      distribuidorCedula: ['', Validators.required],
      distribuidorNombre: ['', Validators.required],
      distribuidorApellido: ['', Validators.required],
      distribuidorPais: ['', Validators.required],
      distribuidorDepartamento: ['', Validators.required],
      distribuidorCiudad: ['', Validators.required],
      distribuidorCelular: ['', [Validators.required, Validators.pattern(/^3[\d]{9}$/)]],
      distribuidorTelefono: [''],
      distribuidorCorreo: ['', [Validators.required, Validators.email]],
      distribuidorContraseña: ['', Validators.required],
    });
    this.juridicoForm = this.formBuilder.group({
      juridicoNombreEstablecimiento: ['', Validators.required],
      juridicoRazon: [''],
      juridicoNit: [''],
      url_pago: [''],
      juridicoEstablecimiento: ['', Validators.required],
      juridicoDescripcion: ['', [Validators.required, Validators.pattern(/^[\s\S]{20,500}$/)]],
      juridicoPais: ['', Validators.required],
      juridicoDepartamento: ['', Validators.required],
      juridicoCiudad: ['', Validators.required],
      juridicoDireccion: ['', Validators.required],
      juridicoTelefono: ['', [Validators.required, Validators.pattern(/^(?=[0-9]*$)(?:.{7}|.{10})$/)]],
      juridicoTelefono2: [''],
      juridicoPropietarioTipo: ['', Validators.required],
      juridicoPropietarioCedula: ['', Validators.required],
      juridicoPropietarioNombre: ['', Validators.required],
      juridicoPropietarioApellido: ['', Validators.required],
      juridicoPropietarioTelefono: ['', [Validators.required, Validators.pattern(/^(?=[0-9]*$)(?:.{7}|.{10})$/)]],
      juridicoPropietarioCorreo: ['', [Validators.required, Validators.email]],
    });
    this.establecimientoForm = this.formBuilder.group({
      establecimientoTipo: ['', Validators.required],
      establecimientoCedula: ['', Validators.required],
      establecimientoNombre: ['', Validators.required],
      establecimientoApellido: ['', Validators.required],
      establecimientoPais: ['', Validators.required],
      establecimientoDepartamento: ['', Validators.required],
      establecimientoCiudad: ['', Validators.required],
      establecimientoEstablecimiento: ['', Validators.required],
      establecimientoCelular: ['', [Validators.required, Validators.pattern(/^3[\d]{9}$/)]],
      establecimientoTelefono: [''],
    });
    this.establecimientoCredencialesForm = this.formBuilder.group({
      establecimientoCorreo: ['', [Validators.required, Validators.email]],
      establecimientoContraseña: ['', Validators.required],
      establecimientoTerminos: ['', Validators.required],
    });
    this.establecimientoTiempoEntregaForm = this.formBuilder.group({
      establecimeintoAtencionSemana: [''],
      establecimeintoAtencionSabado: [''],
      establecimeintoAtencionDomingo: [''],
      establecimeintoMetodoPagoCredito: [''],
      establecimeintoMetodoPagoEfectivo: [''],
      establecimeintoMetodoPagoTransferencia: [''],
      establecimeintoMetodoPagoDatafono: [''],
    });
    this.establecimientoCoberturaForm = this.formBuilder.group({
      coberturaPais: [''],
      coberturaDepartamento: [''],
      coberturaCiudad: [''],
      coberturaDireccion: [''],
    });
  }

  async ngOnInit() {
    this.obtenerPuntosEntrega();
    /**Obtiene departamentos y ciudades de Colombia com API Imagine Apps*/
    this.listaColores = this.mapService.listaColoresPoligono();
    await this.places.getDepartmentFromServerImagine();
    this.departamentos = this.places.departments_colombia;
    this.getDistribuidor();
    if (this.registroComp.dist_area_cobertura.length === 0) {
      this.mapa_en_blanco = true;
    }
  }

  ngDoCheck(): void {
    /** Si existe cobertura mustra solo un mapa de lectura, en caso contrario un editable */
    if (this.poligono_final.length !== 0) {
      this.mapa_en_blanco = false;
    } else {
      this.mapa_en_blanco = true;
    }
    /** Guarda el poligono de cobertura para el formulario */
    this.registroComp.dist_area_cobertura = this.poligono_final;
    /** Validadores */
    if (this.registroComp.step == 6) {
      /**Validator para checkbox */
      this.checkDiaDeAtencion();
      this.checkMetodoPago();
      /**Captura la información de los sliders */
      this.registroComp.dist_horario_lunes_a_viernes =
        this.valueToTime(this.minValueEntreSemana) + ' - ' + this.valueToTime(this.maxValueEntreSemana);
      this.registroComp.dist_horario_sabados =
        this.valueToTime(this.minValueSabados) + ' - ' + this.valueToTime(this.maxValueSabados);
      this.registroComp.dist_horario_domingos =
        this.valueToTime(this.minValueDomingos) + ' - ' + this.valueToTime(this.maxValueDomingos);
    }
    this.comparaIDTrabajadorYPropietario();
    this.recuperarDistribuidorPorNITCC(this.registroComp.dist_t_nit_cc);
    this.habilitarBoton();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getCoordAutocomplete();
    }, 500);
  }

  getCoordAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      const autoCompleteMaps = new google.maps.places.Autocomplete(this.direccionRegistro.nativeElement);
      autoCompleteMaps.addListener(`place_changed`, () => {
        this.zone.run(() => {
          // some details
          const place: google.maps.places.PlaceResult = autoCompleteMaps.getPlace();
          const address = place.formatted_address;
          this.map.lat = place.geometry?.location.lat();
          this.map.lng = place.geometry?.location.lng();
          if (address) {
            this.registroComp.dist_direccion = address;
          }
        });
      });
    });
  }

  /************************************************* Mapas *************************************************/
  /**
   * Metodo para obtener departamentos y ciudades mediante API Imagine Apps
   */
  async getPlaces(tipo: string) {
    let id_dpto = 0;
    let consulta_departamento = '';
    if (tipo == 'distribuidor') {
      consulta_departamento = this.registroComp.dist_departamento;
      for (const dpto of this.places.departments_colombia) {
        if (dpto.name == consulta_departamento) {
          break;
        }
        id_dpto++;
      }
      if (this.places.departments_colombia[id_dpto] != undefined) {
        await this.places.getCitiesFromServerImagine(this.places.departments_colombia[id_dpto].code);
        this.ciudades = this.places.cities_colombia;
        this.ciudadesPoligono = this.places.cities_colombia;
      } else {
        this.ciudades = [];
      }
    } else if (tipo == 'establecimiento') {
      consulta_departamento = this.registroComp.dist_t_departamento;
      for (const dpto of this.places.departments_colombia) {
        if (dpto.name == consulta_departamento) {
          break;
        }
        id_dpto++;
      }
      if (this.places.departments_colombia[id_dpto] != undefined) {
        await this.places.getCitiesFromServerImagine(this.places.departments_colombia[id_dpto].code);
        this.ciudades_trab = this.places.cities_colombia;
      } else {
        this.ciudades_trab = [];
      }
    } else if (tipo == 'juridico') {
      consulta_departamento = this.registroComp.dist_departamento_empresa;
      for (const dpto of this.places.departments_colombia) {
        if (dpto.name == consulta_departamento) {
          break;
        }
        id_dpto++;
      }
      if (this.places.departments_colombia[id_dpto] != undefined) {
        await this.places.getCitiesFromServerImagine(this.places.departments_colombia[id_dpto].code);
        this.ciudades_empresa = this.places.cities_colombia;
      } else {
        this.ciudades_empresa = [];
      }
    }
  }

  async getPlacesPoligonos() {
    let id_dpto = 0;
    for (const dpto of this.places.departments_colombia) {
      if (dpto.name == this.departamentoPoligono) {
        break;
      }
      id_dpto++;
    }
    if (this.places.departments_colombia[id_dpto] != undefined) {
      this.ciudadesPoligono = await this.places.getCitiesFromServerImagine(
        this.places.departments_colombia[id_dpto].code
      );
    } else {
      this.ciudadesPoligono = [];
    }
  }

  /**
   * Toma la dirección ingresada para la ciudad seleccionada
   * y ajusta las coordenadas en el mapa para mostrarlas
   */
  buscarDireccion() {
    if (this.registroComp.dist_ciudad_empresa != '') {
      this.ciudadPoligono = this.registroComp.dist_ciudad_empresa;
      this.departamentoPoligono = this.registroComp.dist_departamento_empresa;
      const dir =
        this.registroComp.dist_ciudad_empresa + ', ' + this.registroComp.dist_departamento_empresa + ', Colombia';
      this.mapService
        .getLatLong(dir)
        .toPromise()
        .then((resp: any) => {
          console.log(resp)
          if (resp.status == 'OK') {
            if (resp.results[0]) {
              this.map.lat = resp.results[0].geometry.location.lat;
              this.map.lng = resp.results[0].geometry.location.lng;
              this.mapPoligono.lat = resp.results[0].geometry.location.lat;
              this.mapPoligono.lng = resp.results[0].geometry.location.lng;
              const address = resp.results[0].formatted_address;
              if (address) {
                this.registroComp.dist_direccion = address;
              }
              const bounds = new google.maps.LatLngBounds();
              bounds.extend(this.map);
              this.agmMap.mapReady.subscribe((map) => {
                map.fitBounds(bounds);
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //this.deleteSelectedShape();
    }
  }

  generarDireccionPoligono() {
    this.mapService
      .getLatLong(`${this.ciudadPoligono}, ${this.departamentoPoligono}`)
      .toPromise()
      .then((resp: any) => {
        if (resp.status == 'OK') {
          if (resp.results[0]) {
            this.mapPoligono.lat = resp.results[0].geometry.location.lat;
            this.mapPoligono.lng = resp.results[0].geometry.location.lng;
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  enterDireccion() {
    let direccionBusqueda = '';
    if (this.registroComp.dist_ciudad_empresa) {
      direccionBusqueda = `${this.registroComp.dist_departamento_empresa},
        ${this.registroComp.dist_ciudad_empresa}`;
    }
    if (this.registroComp.dist_direccion) {
      direccionBusqueda = direccionBusqueda
        ? `${this.registroComp.dist_direccion}, ${direccionBusqueda}`
        : this.registroComp.dist_direccion;
    }
    this.mapService
      .getLatLong(direccionBusqueda)
      .toPromise()
      .then((resp_map: any) => {
        if (resp_map.status == 'OK') {
          if (resp_map.results[0]) {
            this.map.lat = resp_map.results[0].geometry.location.lat;
            this.map.lng = resp_map.results[0].geometry.location.lng;
          }
        }
      });
  }

  /********* VISUALIZAR MAPA PREVIAMENTE GUARDADO *********/
  /**
   * Carga la cobertura ya guardada en la base de datos solo como visualización para el usuario
   * de necesitar editarla o cambiarla deberá crear una nueva
   */
  drawPolygon(map: any) {
    const coordenadas_iniciales: any = [];
    this.poligono_final.forEach((element: any) => {
      coordenadas_iniciales.push({
        lat: element.latitud,
        lng: element.longitud,
      });
    });
    this.nueva_cobertura = new google.maps.Polygon({
      paths: coordenadas_iniciales,
      editable: false,
      draggable: false,
    });
    this.nueva_cobertura.setMap(map);
  }

  /**
   * Borra el poligono guardado en la base de datos para dar paso a la creación de un nuevo poligono
   */
  deleteDefaultShape() {
    this.nueva_cobertura.setMap(null);
    this.poligono_final = [];
    this.mapa_en_blanco = true;
  }

  /********* CREAR NUEVO MAPA *********/
  /**
   * Inicializa el manejador del mapa que muestra la dirección ingresada para el distribuidor
   * @param map La referencia al mapa a inicializar
   */
  onMapReady(map: any) {
    this.referenciaMapa = map;
    this.initDrawingManager(map);
  }

  /**
   * Inicializa el manejador de dibujo del mapa para el area de cobertura
   * @param map La referencia al mapa a inicializar
   */
  initDrawingManager = (map: any) => {
    this.drawing_map = map;
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        draggable: false,
        editable: true,
        fillColor: this.listaColores[this.coordMulti.length],
        strokeWeight: 0,
        fillOpacity: 0.6,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);
    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event: any) => {
      const indexPolygon = event.overlay.zIndex;
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        const paths = event.overlay.getPaths();
        for (let p = 0; p < paths.getLength(); p++) {
          google.maps.event.addListener(paths.getAt(p), 'set_at', () => {
            if (!event.overlay.drag) {
              this.updatePointList(event.overlay.getPath(), indexPolygon);
            }
          });
          google.maps.event.addListener(paths.getAt(p), 'insert_at', () => {
            this.updatePointList(event.overlay.getPath(), indexPolygon);
          });
          google.maps.event.addListener(paths.getAt(p), 'remove_at', () => {});
        }
        this.updatePointList(event.overlay.getPath(), this.coordMulti.length, event.overlay);
      }
    });
  };

  /**
   * Toma la figura creada por el usuario y guarda las coordenadas de los nodos para que luego puedan ser guardados
   * @param path El camino hecho por el usuario
   */
  updatePointList(path: any, index: any, overlay?: any) {
    const nueva_cobertura = [];
    const len = path.getLength();

    if (this.coordMulti[index]) {
      for (let i = 0; i < len; i++) {
        nueva_cobertura.push(path.getAt(i).toJSON());
      }
      this.coordMulti[index] = nueva_cobertura;
    } else {
      for (let i = 0; i < len; i++) {
        nueva_cobertura.push(path.getAt(i).toJSON());
      }
      if (nueva_cobertura.length < 3) {
        overlay.setMap(null);
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'La figura debe de tener al menos 3 lados';
        modalRef.componentInstance.btn_msg = 'Volver';
      } else {
        nueva_cobertura.push(nueva_cobertura[0]);
        this.agregarPoligono();
        this.coordMulti.push(nueva_cobertura);
        this.polygonsList = [...this.polygonsList, overlay];
        this.changePolygonMapOptions();
      }
    }
  }

  agregarPoligono() {
    this.valoresPedido = [
      ...this.valoresPedido,
      {
        tipo_promesa: 'horas',
        valor_promesa: 1,
        valor_pedido: this.currencyPipe.transform(0, '$ ', 'symbol', '1.0-0'),
      },
    ];
  }

  changePolygonMapOptions() {
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        draggable: false,
        editable: true,
        fillColor: this.listaColores[this.coordMulti.length],
        strokeWeight: 0,
        fillOpacity: 0.6,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };
    this.drawingManager.setOptions(options);
  }

  /**
   * Borra el poligono seleccionado por el usuario en el mapa
   */
  deleteSelectedShape() {
    this.selectedShape.setEditable(false);
    this.selectedShape.setMap(null);
    this.selectedShape = null;
    this.selectedArea = 0;
    this.nueva_cobertura = [];
    this.poligono_final = [];
    this.initDrawingManager(this.drawing_map);
    this.drawingManager.setOptions({
      drawingControl: true,
    });
  }

  /************************************************* Get data *************************************************/
  /**
   * Recuperar la lista de distribuidores registrados para el registro de un trabajador
   */
  getDistribuidor() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    this.restService
      .get('distribuidor')
      .toPromise()
      .then((resp: any) => {
        for (const aux of resp) {
          this.distribuidores.push({
            _id: aux._id,
            nit_cc: aux.nit_cc,
            nombre: aux.nombre,
            logo: aux.logo || '',
          });
        }
      })
      .catch((err) => {
        console.log(err);
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'Ocurrió un error recuperando la información de los distribuidores registrados. Por favor intenta de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Volver al registro';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/login']);
        };
      });
  }

  /**
   * Revisa la lista con la info de los distribuidores y devuelve
   * el distribuidor cuyo NIT/CC coincide con el que entra por parámetro
   * @param pNitCC El NIT/CC a buscar en la lista de distribuidores
   * @returns El distribuidor encontrado, o un distribuidor vacío si
   * ningún distribuidor cumple con los requisitos
   */
  recuperarDistribuidorPorNITCC(pNitCC: string): { _id: string; nit_cc: string; nombre: string; logo: string } {
    let ans: { _id: string; nit_cc: string; nombre: string; logo: string } = {
      _id: '',
      nit_cc: '',
      nombre: '',
      logo: '',
    };
    for (const aux of this.distribuidores) {
      if (aux.nit_cc == pNitCC) {
        ans = aux;
        break;
      }
    }
    if (this.registroComp.dist_t_nit_cc != undefined && ans._id.length > 4) {
      this.registroComp.dist_encontrado.nombre = ans.nombre;
      this.registroComp.dist_encontrado.nit_cc = ans.nit_cc;
      this.registroComp.dist_encontrado._id = ans._id;
      this.registroComp.dist_encontrado.logo = ans.logo;
    } else {
      this.registroComp.dist_encontrado.nombre = '';
      this.registroComp.dist_encontrado.nit_cc = '';
      this.registroComp.dist_encontrado._id = '';
      this.registroComp.dist_encontrado.logo = '';
    }
    return ans;
  }

  /************************************************* Subir archivos *************************************************/
  /**
   * Recibe un archivo del usuario y lo guarda en el indice correcto del arreglo de archivos a subir. Si el archivo
   * a subir no tiene el formato correcto, no se guarda y se muestra un mensaje de error
   * @param event El evento generado al subir el archivo
   * @param index El indice del arreglo de archivos
   */
  handleFileInput(event: any, index: number) {
    this.registroComp.error_formato_pdf = false;
    this.registroComp.error_formato_icon = false;

    const file: File = event.target.files[0];
    if (file.size > 2000000) {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Este archivo supera el máximo de 2MB permitido!';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else if (file.name.slice(-4) != '.pdf' && index != 3) {
      const modalRef = this.modalService.open(SimpleComponent);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = '¡Solo archivos PDF están permitidos!';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    } else {
      if (file != null) {
        const file_split: string[] = file.name.split('.');
        const file_end: string = file_split[file_split.length - 1].trim().toLowerCase();
        if (index == 3) {
          //Se intenta subir un archivo de imagen
          if (this.supported_imgs.includes(file_end)) {
            this.registroComp.dist_archivos[index] = file;
            this.logo_path =
              this.sanitizer.sanitize(
                SecurityContext.NONE,
                this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.registroComp.dist_archivos[index]))
              ) || '';
          } else {
            this.registroComp.error_formato_icon = true;
          }
        } else {
          //Se intenta subir un pdf
          if (file_end == 'pdf') {
            this.registroComp.dist_archivos[index] = file;
          } else {
            this.registroComp.error_formato_pdf = true;
          }
        }
      } else {
        this.registroComp.dist_archivos[index] = file;
        if (index == 3) {
          this.logo_path = undefined;
        }
      }
    }
  }

  /************************************************* Validadores *************************************************/
  /**
   * Validators para los checkbox; se debe seleccionar por lo menos una opción
   * */
  checkDiaDeAtencion() {
    if (
      this.establecimientoTiempoEntregaForm.value.establecimeintoAtencionSemana == true ||
      this.establecimientoTiempoEntregaForm.value.establecimeintoAtencionSabado == true ||
      this.establecimientoTiempoEntregaForm.value.establecimeintoAtencionDomingo == true
    ) {
      this.validatorDiasAtencionError = false;
    } else {
      this.validatorDiasAtencionError = true;
    }
  }
  checkMetodoPago() {
    if (
      this.establecimientoTiempoEntregaForm.value.establecimeintoMetodoPagoCredito == true ||
      this.establecimientoTiempoEntregaForm.value.establecimeintoMetodoPagoEfectivo == true ||
      this.establecimientoTiempoEntregaForm.value.establecimeintoMetodoPagoTransferencia == true ||
      this.establecimientoTiempoEntregaForm.value.establecimeintoMetodoPagoDatafono == true
    ) {
      this.validatorTiempoEntregaError = false;
    } else {
      this.validatorTiempoEntregaError = true;
    }
  }

  /**
   * Este metodo tiene como objeto verificar si los formularios son invalidos
   * En caso de serlos mostrará un toast como alerta y marcara los campos errados
   * */
  checkIfFormIsInvalid() {
    if (this.router.url == '/registro/distribuidor') {
      //Propietario Natural o Jurídico
      if (this.registroComp.distribiudor_perfil_seleccionado == 'Propietario o representante legal') {
        if (this.registroComp.step == 4) {
          this.distribuidorForm.markAllAsTouched();
        }
        if (this.registroComp.step == 5) {
          this.juridicoForm.markAllAsTouched();
        }
        if (this.registroComp.step == 6) {
          this.establecimientoTiempoEntregaForm.markAllAsTouched();
        }
        if (this.registroComp.step == 7) {
          this.establecimientoCoberturaForm.markAllAsTouched();
        }
      }
      //Trabajador
      if (this.registroComp.distribiudor_perfil_seleccionado == 'Trabajador') {
        if (this.registroComp.step == 4) {
          this.establecimientoForm.markAllAsTouched();
        }
        if (this.registroComp.step == 5) {
          this.establecimientoCredencialesForm.markAllAsTouched();
        }
      }
    }
    this.showDanger();
  }

  /**
   * Metodos para mostrar un toast si existe un input invalido en el formulario
   */
  showDanger() {
    this.toastService.show('Tienes campos pendientes por revisar', { classname: 'bg-danger text-light', delay: 10000 });
  }
  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateOnlyText(event: any) {
    const keyCode = event.keyCode;
    if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateNumber(event: any) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }

  /**
   * Para el registro del dueño de la empresa, llena automáticamente los datos diligenciados
   * en el paso 4 para el paso 5
   * Ocurre cuando el usuario hace click en "Yo soy el propietario - Natural"
   */
  duplicarDatosPropeitario(click: any) {
    this.registroComp.dist_tipo_doc_identidad_prop = this.registroComp.dist_tipo_documento;
    this.registroComp.dist_doc_identidad_prop = this.registroComp.dist_numero_documento;
    this.registroComp.dist_nombres_prop = this.registroComp.dist_nombres;
    this.registroComp.dist_apellidos_prop = this.registroComp.dist_apellidos;
    this.registroComp.dist_correo_prop = this.registroComp.dist_correo;
    this.registroComp.dist_telefono_prop = this.registroComp.dist_celular;
  }

  /**
   * Para el registro del dueño de la empresa, llena automáticamente los datos diligenciados
   * en el paso 4 para el paso 5
   * Ocurre cuando el usuario hace click en "Yo soy el propietario - Natural"
   */
  borrarDuplicadoDatosPropeitario() {
    this.registroComp.dist_tipo_doc_identidad_prop = '';
    this.registroComp.dist_doc_identidad_prop = '';
    this.registroComp.dist_nombres_prop = '';
    this.registroComp.dist_apellidos_prop = '';
    this.registroComp.dist_correo_prop = '';
    this.registroComp.dist_telefono_prop = '';
  }

  /**
   * Valida si los documentos del trabajador y el propietario son iguales de serlo
   * duplica los datos del propietario, de no serlo garantiza que el flag quede false
   */
  comparaIDTrabajadorYPropietario() {
    /**Por conflicto con la ejecucion de funciones desde el HTML se establece este time out */
    setTimeout(() => {
      if (
        this.registroComp.dist_doc_identidad_prop !== '' &&
        this.registroComp.dist_numero_documento === this.registroComp.dist_doc_identidad_prop &&
        !this.soy_propietario
      ) {
        this.duplicarDatosPropeitario(true);
        this.soy_propietario = true;
      }
      /** Garantiza que si los documentos no son los mismos, el flag estará en false */
      if (
        this.registroComp.dist_numero_documento !== this.registroComp.dist_doc_identidad_prop &&
        this.soy_propietario
      ) {
        this.soy_propietario = false;
      }
    }, 400);
  }

  /**
   * Selecciona el tipo de persona que está siendo registrado
   * @param tipo_persona El tipo de persona. Puede ser "Natural"
   * o "Jurídica"
   */
  seleccionarTipoPersona(tipo_persona_long: string) {
    const tipo_persona = tipo_persona_long.substring(1).substring(1).substring(1);
    this.registroComp.selectTipoPersona(tipo_persona);
  }

  /**
   * Selecciona el tipo de perfil que está siendo registrado
   * @param perfil El tipo de perfil. Puede ser "Propietario
   * o representante legal" o "Trabajador"
   */
  seleccionarPerfil(perfil: string) {
    this.registroComp.selectPerfilDistribuidor(perfil);
  }

  /**
   * Permite traducir un número del slider de horario a una
   * hora legible por el usuario
   * @param value El valor a traducir entre 0.0 y 24.0 en saltos de 0.5
   * @returns Un String entre 0:00 y 24:00 pensado como horas en saltos de 30 minutos
   */
  valueToTime(value: number): string {
    const value_str: string[] = value.toString().split('.');
    const hr: string = value_str[0];
    let mn = '00';
    if (value_str[1] != null) {
      mn = '30';
    }
    return hr + ':' + mn;
  }

  /**
   * Transforma el dinero minimo de compra de número a moneda y viceversa
   * a moneda se utilizará para reemplazar el input y dar mejor UX al usuario
   * y a número plano para guardar el dato correctamente en la base de datos
   * ademas de poder editar mejor el valor en el input
   */
  public transformCurrency() {
    this.registroComp.dist_valor_minimo = this.registroComp.dist_valor_minimo.replace(/[^\d,-]/g, '');
  }
  public transformAmount(event: any) {
    this.registroComp.dist_valor_minimo = this.currencyPipe.transform(event.target.value, '$ ', 'symbol', '1.0-0');
  }

  /**Esta funcion tiene como objeto deshabilitar o habilitar el boton de continuar
   * dependiendo si se han cumplido las condiciones
   * tales como un formulario valido o una seleccion requerida
   */
  habilitarBoton() {
    //Se inicializa siembre en boton deshabilitado, si se cumplen las condiciones se habilita
    this.isBotonHabilitado = false;
    this.isFormularioCompleto = false;
    /**------ Validaciones para Horeca ------ */
    if (this.router.url == '/registro/distribuidor') {
      // --------- Propietario ---------
      if (this.registroComp.distribiudor_perfil_seleccionado == 'Propietario o representante legal') {
        // ---- Paso 2 y 3 -----
        if (this.registroComp.step == 2 || this.registroComp.step == 3) {
          if (this.registroComp.dist_tipo_persona == 'Natural' || this.registroComp.dist_tipo_persona == 'Jurídica') {
            this.isBotonHabilitado = true;
          }
        }
        // ---- Paso 4 ----
        if (this.registroComp.step == 4) {
          /**
           * Validación para campo opcional telfono, dado que es opcional no se valida con Validators
           * Si no cumple se cierra la función con Return, si cumple no entra al return y se habilita el botón.
           */
          if (
            this.registroComp.dist_telefono != undefined &&
            this.registroComp.dist_telefono.toString().length >= 1 &&
            this.registroComp.dist_telefono.toString().length != 7 &&
            this.registroComp.dist_telefono.toString().length != 10
          ) {
            return;
          } else {
            this.isBotonHabilitado = !this.distribuidorForm.invalid;
          }
        }
        // ---- Paso 5 ----
        if (this.registroComp.step == 5) {
          /**
           * Validación para campo opcional telfono, dado que es opcional no se valida con Validators
           * Si no cumple se cierra la función con Return, si cumple no entra al return y se habilita el botón.
           */
          if (
            this.registroComp.dist_telefono_empresa != undefined &&
            this.registroComp.dist_telefono_empresa.toString().length >= 1 &&
            this.registroComp.dist_telefono_empresa.toString().length != 7 &&
            this.registroComp.dist_telefono_empresa.toString().length != 10
          ) {
            return;
          } else {
            if (this.registroComp.dist_tipo_persona == 'Natural') {
              this.isBotonHabilitado = !this.juridicoForm.invalid;
            } else if (
              this.registroComp.dist_tipo_persona == 'Jurídica' &&
              this.registroComp.dist_razon_social != '' &&
              this.registroComp.dist_nit != ''
            ) {
              this.isBotonHabilitado = !this.juridicoForm.invalid;
            }
          }
        }
        // ---- Paso 6 ----
        if (this.registroComp.step == 6) {
          /** Dado que no hay validator para los checkbox se verifican aquí */
          if (this.validatorDiasAtencionError == false && this.validatorTiempoEntregaError == false) {
            this.isBotonHabilitado = !this.establecimientoTiempoEntregaForm.invalid;
          }
        }
        // ---- Paso 7 ----
        if (this.registroComp.step == 7) {
          /**Dado que no hay validator para la selección del área de cobertura
           * se verifica si se ha seleccionado un area en el mapa*/
          let valorPedido = 0;
          if (typeof this.valoresPedido[0]?.valor_pedido === 'string') {
            valorPedido = this.valoresPedido[0]?.valor_pedido.replace(/[^\d,-]/g, '');
          } else {
            valorPedido = 0;
          }
          if (this.coordMulti.length > 0 && valorPedido > 0) {
            this.isBotonHabilitado = true;
          } else {
            this.isBotonHabilitado = false;
          }
        }
        // ---- Paso 8 ----
        if (this.registroComp.step == 8) {
          // Validaciones basicas de documentos
          if (
            this.registroComp.dist_acepto_terminos == true &&
            this.registroComp.dist_archivos[0] != undefined &&
            this.registroComp.dist_archivos[2] != undefined &&
            this.registroComp.dist_archivos[3] != undefined
          ) {
            if (this.registroComp.dist_tipo_persona == 'Natural') {
              // Persona natural no requiere camara de comercio
              this.isBotonHabilitado = true;
              this.isFormularioCompleto = true;
            } else if (
              //Jurdico si requiere camara de comercio
              this.registroComp.dist_tipo_persona == 'Jurídica' &&
              this.registroComp.dist_archivos[1] != undefined
            ) {
              this.isBotonHabilitado = true;
              this.isFormularioCompleto = true;
            }
          }
        }
      }
      // --------- Trabajador ---------
      if (this.registroComp.distribiudor_perfil_seleccionado == 'Trabajador') {
        // ---- Paso 2 -----
        if (this.registroComp.step == 2 || this.registroComp.step == 3) {
          /**Cuando se limpia el input queda con la información de la ultima busqueda
           * por esto forzamos con este primer if que al borrar el input se borren los datos*/
          if (this.registroComp.dist_encontrado.nombre == '') {
            this.isBotonHabilitado = false;
          } else if (this.registroComp.dist_encontrado.nombre != undefined) {
            this.isBotonHabilitado = true;
          }
        }
        // ---- Paso 4 ----
        /**
         * Validación para campo opcional telfono, dado que es opcional no se valida con Validators
         * Si no cumple se cierra la función con Return, si cumple no entra al return y se habilita el botón.
         */
        if (
          this.registroComp.dist_t_telefono != null &&
          this.registroComp.dist_t_telefono.length >= 1 &&
          this.registroComp.dist_t_telefono.length != 7 &&
          this.registroComp.dist_t_telefono.length != 10
        ) {
          this.isBotonHabilitado = false;
          return;
        } else {
          if (this.registroComp.step == 4) {
            this.isBotonHabilitado = !this.establecimientoForm.invalid;
          }
        }
        // ---- Paso 5 ----
        if (this.registroComp.step == 5 && this.registroComp.dist_t_acepto_terminos == true) {
          this.isFormularioCompleto = !this.establecimientoCredencialesForm.invalid;
        }
      }
    }
  }

  continuarPasoSiete() {
    if (this.registroComp.step == 7) {
      this.registroComp.zonas_cobertura = this.formatearCoordenadasEdicion();
      this.setOrderValues(2);
      this.replicarValoresPoligono();
      this.registroComp.datos_poligono = this.valoresPedido;
      this.registroComp.dist_valor_minimo = this.valoresPedido[0].valor_pedido;
      this.registroComp.dist_tiempo_entrega = this.formatearPromesaTexto();
    }
  }

  siguiente() {
    if (this.isBotonHabilitado) {
      this.continuarPasoSiete();
      this.registroComp.continue();
    }
  }

  /************************************************* Otras funcionalidades *************************************************/
  /**
   * Permite retroceder un paso en el formulario de registro
   */
  stepBack() {
    if (this.registroComp.step > 1) {
      this.registroComp.step--;
      this.registroComp.continueDistribuidor();
    }
  }

  /**
   * Message box words counter
   */
  remainingText = 500;
  valueChange(value: string) {
    this.remainingText = 500 - value.length - 1;
  }

  /**
   * Oculta o revela la contraseña al usuario
   */
  mostrarOcultarPassword() {
    this.fieldTextType = !this.fieldTextType;
  }

  /**
   * Abre link en una nueva ventana
   */
  public verDocumento(link: any) {
    window.open('https://featapp.co/politicas', '_blank');
  }

  public mapReadyHandler(map: google.maps.Map): void {
    map.addListener('click', (e: google.maps.MouseEvent) => {
      this.zone.run(() => {
        this.map.lat = e.latLng.lat();
        this.map.lng = e.latLng.lng();

        this.buscarDireccionAutocompletada();
      });
    });
  }

  buscarDireccionAutocompletada() {
    this.mapsAPILoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      const latlng = { lat: this.map.lat, lng: this.map.lng };
      geocoder.geocode({ location: latlng }, (results: any) => {
        if (results[0]) {
          this.registroComp.dist_direccion = results[0].formatted_address;
        }
      });
    });
  }

  determinarCaracteres() {
    if (this.registroComp.dist_descripcion) {
      this.remainingText = 500 - this.registroComp.dist_descripcion.length;
    } else {
      this.remainingText = 500;
    }
  }

  public transformCurrencyValue(index: number, event: any) {
    this.valoresPedido[index].valor_pedido = event.target.value.replace(/[^\d,-]/g, '');
  }

  public transformAmountValue(index: number, event: any) {
    this.valoresPedido[index].valor_pedido = this.currencyPipe.transform(event.target.value, '$ ', 'symbol', '1.0-0');
  }

  setOrderValues(type: number) {
    for (const pedido of this.valoresPedido) {
      if (type === 1) {
        pedido.valor_pedido = this.currencyPipe.transform(pedido.valor_pedido, '$ ', 'symbol', '1.0-0');
      } else if (type === 2) {
        pedido.valor_pedido = pedido.valor_pedido.replace(/[^\d,-]/g, '');
      }
    }
  }

  removePolygon(index: number) {
    this.deleteOnlyPolygon();
    this.polygonsList.splice(index, 1);
    this.coordMulti.splice(index, 1);
    this.valoresPedido.splice(index, 1);
    this.changePolygonMapOptions();
    this.actualizarPoligonosExistentes();
  }

  deleteOnlyPolygon() {
    for (const datosPoli of this.polygonsList) {
      datosPoli.setMap(null);
    }
  }

  actualizarPoligonosExistentes() {
    for (let i = 0; i < this.coordMulti.length; i++) {
      //Add the polygon
      const p = new google.maps.Polygon({
        paths: this.coordMulti[i],
        strokeWeight: 0,
        fillColor: this.listaColores[i],
        fillOpacity: 0.6,
        indexID: i,
        editable: true,
        draggable: false,
      });
      p.setOptions({ fillOpacity: 0.6 });
      this.polygonsList = [...this.polygonsList, p];
      p.setMap(this.referenciaMapa);
    }
  }

  formatearCoordenadasEdicion() {
    let totalCoords: any = [];
    for (const coord of this.coordMulti) {
      let arrCoord: any = [];
      for (const latLng of coord) {
        arrCoord = [...arrCoord, [latLng.lng, latLng.lat]];
      }
      totalCoords = [...totalCoords, [arrCoord]];
    }
    const objetoMultiPoligono = {
      type: 'MultiPolygon',
      coordinates: totalCoords,
    };
    return objetoMultiPoligono;
  }

  replicarValoresPoligono() {
    let promesa;
    let valor;
    let index = 0;
    for (const dato of this.valoresPedido) {
      if (index === 0) {
        promesa = dato.valor_promesa;
        valor = dato.valor_pedido;
      } else {
        dato.valor_promesa = promesa;
        dato.valor_pedido = valor;
      }
      index++;
    }
  }

  construirRangosPromesa() {
    let indexHoras = 0;
    let rangosHoras: any = [];
    for (const hora of this.listaHoras) {
      if (indexHoras > 0 && hora % 2 === 0 && hora < 47) {
        rangosHoras = [...rangosHoras, `${hora} - ${hora + 2}`];
      }
      indexHoras++;
    }
    return rangosHoras;
  }

  formatearPromesaTexto() {
    const rangosHoras = this.construirRangosPromesa();
    const promesaEntrega = parseInt(this.valoresPedido[0].valor_promesa);
    if (promesaEntrega === 1 || promesaEntrega === 2) {
      return '2 - 4 horas';
    }
    for (const rango of rangosHoras) {
      const separacionRango = rango.split(' - ');
      const primerRango = parseInt(separacionRango[0]);
      const segundoRango = parseInt(separacionRango[1]);
      if (promesaEntrega > primerRango && promesaEntrega <= segundoRango) {
        return `${rango} horas`;
      }
    }
    return '46 - 48 horas';
  }

  obtenerPuntosEntrega() {
    this.restService
      .getJWT('punto_entrega/')
      .toPromise()
      .then((resp: any) => {
        const puntos = resp;
        for (const punto of puntos) {
          if (punto.coord && punto.estado === 'Activo') {
            this.coordenadasPuntos = [...this.coordenadasPuntos, { lat: punto.coord?.lat, lng: punto.coord?.lng }];
          }
        }
      });
  }
}
