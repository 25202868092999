import { Component, OnInit } from '@angular/core';
import { faChevronLeft, faFilter, faSearch, faTimes, faCircle } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { FiltroDistribuidoresComponent } from 'src/app/modal/filtro-distribuidores/filtro-distribuidores.component';
import { SeleccionarPuntoEntregaComponent } from 'src/app/modal/seleccionar-punto-entrega/seleccionar-punto-entrega.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { Distribuidor } from 'src/app/models/distribuidor.model';
import { RestService } from 'src/app/services/rest/rest.service';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';

@Component({
  selector: 'app-saldos-promociones',
  templateUrl: './saldos-promociones.component.html',
  styleUrls: ['./saldos-promociones.component.css'],
})
export class SaldosPromocionesComponent implements OnInit {
  faChevronLeft = faChevronLeft;
  faFilter = faFilter;
  faSearch = faSearch;
  faTimes = faTimes;
  faCircle = faCircle;
  faStar = faStar;

  searchTerm = '';

  cards = [
    {
      state: 'Completado',
      img: '../../../assets/img/arepas-bee.png',
      name: 'Dipezcar',
      type: 'Carnicos',
      calification: '3.0',
      ventas: '20',
    },
    {
      state: 'Completado',
      img: '../../../assets/img/arepas-bee.png',
      name: 'Distrilac',
      type: 'Frutas',
      calification: '3.0',
      ventas: '30',
    },
    {
      state: 'Completado',
      img: '../../../assets/img/arepas-bee.png',
      name: 'Casalimpia',
      type: 'Lacteos',
      calification: '3.0',
      ventas: '40',
    },
  ];

  filters = [
    {
      name: 'Todos',
      img: '../../../assets/img/icons/icono-caja-off.png',
    },
    {
      name: 'Carnicos',
      img: '../../../assets/img/icons/icono-carne-off.png',
    },
    {
      name: 'Lacteos',
      img: '../../../assets/img/icons/icono-lacteos-off.png',
    },
    {
      name: 'Bebidas',
      img: '../../../assets/img/icons/icono-bebidas-off.png',
    },
    {
      name: 'Frutas y verduras',
      img: '../../../assets/img/icons/icono-frutas-verduras-off.png',
    },
    {
      name: 'Maquinaria e implementos',
      img: '../../../assets/img/icons/icono-maquinaria-implementos-off.png',
    },
    {
      name: 'Licores',
      img: '../../../assets/img/icons/icono-licores-off.png',
    },
    {
      name: 'Aseo y otros',
      img: '../../../assets/img/icons/icono-aseo-off.png',
    },
    {
      name: 'Especializado - general',
      img: '',
    },
  ];
  public buffer_distribuidores: any;

  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
    public localStorage: LocalStorageService,
    private router: Router,
    private rest: RestService,
    private distribuidorService: DistribuidorService
  ) {}

  /** Todos los distribuidores */
  public distribuidores: any;

  /** Variables de filtrado */
  ranking = 0;
  tiempo_entrega = 'Selecciona';
  categorias: boolean[] = [true, false, false, false, false, false, false, false, false];

  /** Flags para indicar si un distribuidor ya está relacionado al punto de entrega */
  public vinculado: boolean[] = [];

  /** Punto de entrega seleccionado */
  public punto_entrega?: PuntoEntrega;

  /** Evita que al hacer click por fuera se cierre el modal */
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };

  ngOnInit(): void {
    if (this.auth.punto_seleccionado == undefined) {
      this.selecionarPunto();
    } else {
      this.punto_entrega = this.auth.punto_seleccionado;
      this.punto_entrega = this.auth.punto_seleccionado;
      this.fetchDistribuidores();
    }
  }

  /**Limpiar busqueda */
  clearSearch() {
    this.searchTerm = '';
    this.fetchFilteredDistribuidores();
  }

  fetchDistribuidores() {
    this.rest
      .getJWT(`/miniaturas-saldos-promociones/distribuidores/${this.punto_entrega?._id}`)
      .toPromise()
      .then((resp: any) => {
        this.distribuidores = resp;
        this.buffer_distribuidores = resp;
      })
      .catch((err) => {
        console.log(err);
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/inicio']);
        };
      });
  }

  /**
   * Entra el ID de un distribuidor y se encuentra su posición en el arreglo de disrtibidoress
   * @param id_dist El ID del distribuidor a encontrar
   * @returns La posición en el arreglo de distribuidores
   */
  encontrarIndexDistribuidorPorID(id_dist: string): number {
    let ans = -1;

    for (const aux of this.distribuidores) {
      if (aux._id == id_dist) {
        ans = this.distribuidores.indexOf(aux);
      }
    }

    return ans;
  }

  /**
   * Lanza un modal para seleccionar el punto de entrega sobre el que se
   * van a gestionar las solicitudes
   */
  selecionarPunto() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'modal-selecionar-punto',
    };
    const modalRef = this.modalService.open(SeleccionarPuntoEntregaComponent, ngbModalOptions);
    modalRef.componentInstance.callback = () => {
      this.punto_entrega = this.auth.punto_seleccionado;
      this.localStorage.setItem<any>('punto_entrega_seleccionado', this.punto_entrega);
      this.fetchDistribuidores();
    };
  }

  verDistribuidor(id: any) {
    this.localStorage.setItem<any>('ver_saldos', true);
    this.router.navigate(['/distribuidores/' + id]);
  }
  fetchFilteredDistribuidores(): void {
    if (this.searchTerm !== '') {
      this.distribuidorService.getFilteredDistribuidores(this.searchTerm).subscribe((Distribuidores) => {
        if (Distribuidores.mensaje) {
          this.distribuidores = [];
        } else {
          this.distribuidores = Distribuidores;
        }
      });
    } else {
      this.distribuidores = [];
      this.fetchDistribuidores();
    }
  }

  onFiltrar() {
    const modalRef = this.modalService.open(FiltroDistribuidoresComponent, {
      centered: true,
      windowClass: 'modal-filtro',
    });

    modalRef.componentInstance.ranking = this.ranking;

    modalRef.componentInstance.tiempo_entrega = this.tiempo_entrega;
    console.log(this.tiempo_entrega);

    modalRef.componentInstance.todos = this.categorias[0];
    modalRef.componentInstance.carnicos = this.categorias[1];
    modalRef.componentInstance.lacteos = this.categorias[2];
    modalRef.componentInstance.bebidas = this.categorias[3];
    modalRef.componentInstance.frutas = this.categorias[4];
    modalRef.componentInstance.maquinaria = this.categorias[5];
    modalRef.componentInstance.licores = this.categorias[6];
    modalRef.componentInstance.aseo = this.categorias[7];
    modalRef.componentInstance.especializado = this.categorias[8];
    modalRef.componentInstance.distribuidores_componente_padre = this.buffer_distribuidores;
    modalRef.componentInstance.punto_entrega = this.punto_entrega;

    modalRef.componentInstance.callback = (
      rank: number,
      tiempos_entrega: string,
      todos_filtros_desactivados: boolean,
      categorias: boolean[],
      distribuidores_componente_padre: any[]
    ) => {
      this.ranking = rank;
      this.tiempo_entrega = tiempos_entrega;
      this.categorias = categorias;
      this.distribuidores = distribuidores_componente_padre;
    };
  }
}
