import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faChevronLeft, faChevronRight, faCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CrearTrabajadorComponent } from 'src/app/modal/crear-trabajador/crear-trabajador.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Trabajador } from 'src/app/models/trabajador.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';

@Component({
  selector: 'app-trabajadores',
  templateUrl: './trabajadores.component.html',
  styleUrls: ['./trabajadores.component.css'],
})
export class TrabajadoresComponent implements OnInit {
  // Evita que se pueda hacer click fuera del modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };
  constructor(
    private modalService: NgbModal,
    private restService: RestService,
    private authService: AuthService,
    private router: Router
  ) {}

  users = false;

  /** Referencias a íconos FontAwesome para la UI */
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faCircle = faCircle;

  /** Listado de trabajadores asociados cuya solicitud está en ¨Pendiente¨ o "Aprobado" */
  trabajadores_pendientes: Trabajador[] = [];
  trabajadores_aprobados: Trabajador[] = [];
  trabajadores_desactivados: Trabajador[] = [];

  async ngOnInit() {
    await this.traerTrabajadores();
  }

  /**
   * Recorre la lista de trabajadores y toma aquellos que estén registrados con este
   * usuario horeca. Luego, revisa el estado de la solicitud de cada trabajador y
   * los pone en trabajadores_pendientes o trabajadores_aprobados según sea apropiado
   */
  async traerTrabajadores() {
    this.trabajadores_pendientes = [];
    this.trabajadores_aprobados = [];
    this.restService
      .getJWT(`trabajadores_de_horeca/${this.authService.user?.usuario_horeca || ''}`)
      .toPromise()
      .then((resp: any) => {
        let trab_aux: Trabajador;
        Promise.all(
          resp.map(async (actual: any) => {
            console.log('actual', actual);
            trab_aux = new Trabajador(
              actual.nombres,
              actual.apellidos,
              actual.correo,
              actual.clave,
              actual.telefono,
              actual.celular || '',
              actual.pais,
              actual.departamento,
              actual.ciudad,
              actual.tipo_documento,
              actual.numero_documento || '0',
              actual.tipo_trabajador,
              actual.solicitud_vinculacion,
              resp.show_slides,
              actual.usuario_horeca,
              actual.puntos_entrega || [],
              undefined,
              undefined,
              actual.crado_por_horeca || false,
              actual._id
            );
            if (trab_aux.solicitud_vinculacion == 'Pendiente') {
              this.trabajadores_pendientes.push(trab_aux);
            } else if (trab_aux.solicitud_vinculacion == 'Aprobado') {
              this.trabajadores_aprobados.push(trab_aux);
            } else if (trab_aux.solicitud_vinculacion == 'Rechazado') {
              this.trabajadores_desactivados.push(trab_aux);
            }
          })
        );
      })
      .catch((err) => {
        console.log(err);
        const ngbModalOptions: NgbModalOptions = {
          //Evita que al hacer click por fuera se cierre el modal
          backdrop: 'static',
          keyboard: false,
        };
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'No fue posible recuperar la lista de trabajadores. Por favor intenta de nuevo más tarde';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['inicio']);
        };
      });
  }

  /**
   * Lanza el modal de formulario para crear un nuevo trabajador y le pasa como
   * callback la función de traerTrabajadores() para que, una vez se registre
   * el nuevo trabajador, se actualice la lista
   */
  onCreateUser() {
    const modalRef = this.modalService.open(CrearTrabajadorComponent, {
      centered: true,
      size: 'xl',
      windowClass: 'modal-crear-trabajador',
    });

    modalRef.componentInstance.callback = () => {
      this.traerTrabajadores();
    };
  }

  verificaRechazado(index: number, nuevo_estado: string) {
    const modalRef = this.modalService.open(ConfirmacionComponent, this.ngbModalOptions);
    modalRef.componentInstance.msg = '¿Estás seguro de rechazar la solicitud?';
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = 'Si, rechazar';
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      this.actualizarSolicitudTrabajador(index, nuevo_estado);
    };
  }
  /**
   * Actualiza la solicitud del trabajador cuya solicitud se encuentra en "Pendiente"
   * @param index El sitio en el arreglo de trabajadores pendientes en el que se encuentra
   * el trabajador actualizar
   * @param nuevo_estado El estado a asignarle al trabajador. Puede ser "Aprobado" o "Rechazado"
   */
  actualizarSolicitudTrabajador(index: number, nuevo_estado: string) {
    const obj_aux = {
      solicitud_vinculacion: nuevo_estado,
    };

    const id: string = this.trabajadores_pendientes[index]._id || '';

    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };

    const msg: string =
      `El trabajador ` +
      `${this.trabajadores_pendientes[index].nombres} ${this.trabajadores_pendientes[index].apellidos} ` +
      `fue ${nuevo_estado.toLowerCase()} con éxito`;

    this.restService
      .putJWT('trabajador/' + id, obj_aux)
      .toPromise()
      .then(() => {
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Genial!';
        modalRef.componentInstance.msg = msg;
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          window.location.reload();
        };
      })
      .catch(() => {
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Esta solicitud no pudo ser actualizada. Intenta de nuevo más tarde';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {};
      });
  }

  /**
   * Actualiza el estado del trabajador seleccionado para reactivarlo en el sistema
   * @param index El sitio en el arreglo de trabajadores desactivados en el que se encuentra
   * el trabajador desactivado
   */
  async reactivarTrabajador(index: number) {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
    };

    try {
      const obj_aux = {
        solicitud_vinculacion: 'Aprobado',
      };

      await this.restService.putJWT(`trabajador/${this.trabajadores_desactivados[index]._id}`, obj_aux).toPromise();

      const msg: string =
        `El trabajador ` +
        `${this.trabajadores_desactivados[index].nombres} ${this.trabajadores_desactivados[index].apellidos} ` +
        `fue reactivado con éxito`;

      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
      modalRef.componentInstance.title = '¡Genial!';
      modalRef.componentInstance.msg = msg;
      modalRef.componentInstance.btn_msg = 'Listo';
      modalRef.componentInstance.close_callback = () => {
        window.location.reload();
      };
    } catch (err) {
      console.log(err);
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Este trabajador no pudo ser reactivado. Intenta de nuevo más tarde';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {};
    }
  }
}
