import { Component, OnInit, Input, HostListener, Inject } from '@angular/core';
import { PublicacionService } from '../../services/publicacion/publicacion.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faHeart, faComment } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faSHeart, faPaperPlane, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Router } from '@angular/router';
import { RestService } from 'src/app/services/rest/rest.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-publicaciones',
  templateUrl: './publicaciones.component.html',
  styleUrls: ['./publicaciones.component.css'],
})
export class PublicacionesComponent implements OnInit {
  //Iconos
  public faHeart = faHeart;
  public faSHeart = faSHeart;
  public faComment = faComment;
  public faPaperPlane = faPaperPlane;
  public faChevronUp = faChevronUp;
  //Imagen  placeholder
  public distribuidor_placeholder = '../../../assets/img/icon-organizacion.png';
  public feat_placeholder = '../../../assets/img/Landing/icono-negativo.png';
  public publicacion_placeholder = '../../../assets/img/icon-warning-amarillo.png';
  //Filtro para mostrar todos las publicaciones o solo las del usuario
  public mis_publicaciones_child = false;
  //Guarda la informacion de cada trabajador para buscar el nombre de quien hace cada publicación
  public informacionTrabajador: any;
  //Datos del formulario para guardar un comentario
  userInfo = JSON.parse(localStorage.getItem('auth') || '');
  user = this.userInfo['user']['_id'];
  userloged = this.userInfo['user']['_id'];
  idCurrentUserInfo = this.userInfo['user']['_id'];
  idUserInfo: any;
  userLogo: any;
  // Logo usuari
  public logo: any;
  //Ver mas comentarios
  public readMore = false;
  //Booleano para mostrar de scroll top
  public show_button = false;
  //Booleano para mostrar spinner de carga
  public show_spinner = false;
  //Guarda todas las publicaciones
  public publicaciones: any[] = [];
  //Guarda todas las publicaciones ordenandas por fech
  public publicaciones_ordenadas: any[] = [];
  //Guarda las publicaciones que se van mostrando en el DOM
  public publicaciones_scroll_infinito: any[] = [];
  public publicaciones_scroll_infinito_propias: any[] = [];
  // Guarda los likes de las publucacione
  public likes: any;
  //Publicaciones a mostrar en el DOM
  public publicaciones_mostradas = 0;
  //Formulario reactivo
  public data = new FormData();
  public form!: FormGroup;
  public formLike!: FormGroup;
  // Tipo de usuario logueado
  public tipo_usuario: any;
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  //Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private publicationService: PublicacionService,
    private formBuilder: FormBuilder,
    private router: Router,
    private rest: RestService,
    private modalService: NgbModal
  ) {
    this.form = this.formBuilder.group({
      contenido: [''],
      usuario: [this.user],
      autor: [this.idUserInfo],
      publicacion: [''],
      createdAt: [Date.now()],
      logo_autor: [this.userLogo],
    });
    this.formLike = this.formBuilder.group({
      usuario: [this.user],
      publicacion: [''],
      estado: [''],
    });
    this.fetchLikes();
  }

  /**
   * Comunicación con componente padre
   * Recibe instrucción para filtrar publicaciones del usuario
   * */
  @Input()
  set misPublicaciones(misPublicaciones: boolean) {
    console.log('Mis publicaciones', misPublicaciones);
    this.mis_publicaciones_child = misPublicaciones;
  }

  ngOnInit() {
    this.rest
      .get(`tipo_empresa_trabajador/${this.user}`)
      .toPromise()
      .then(
        (resp: any) => {
          this.tipo_usuario = resp;
          // Datos del formulario para guardar un comentario, se usa el idUserInfo como autor
          if (this.tipo_usuario === 'distribuidor') {
            this.idUserInfo = this.userInfo.user_distribuidor._id;
            this.userLogo = this.userInfo.user_distribuidor.logo;
            this.publicationService.getUserDistribuidor(this.idUserInfo).subscribe((res) => {
              this.logo = res.logo;
            });
          } else if (this.tipo_usuario === 'usuario_horeca') {
            this.idUserInfo = this.userInfo['user_horeca']['_id'];
            this.userLogo = this.userInfo['user_horeca']['logo'];
            this.publicationService.getUserHoreca(this.idUserInfo).subscribe((res) => {
              this.logo = res.logo;
            });
          } else if (this.tipo_usuario === 'organizacion') {
            this.idUserInfo = this.userInfo['user_organizacion']['_id'];
            this.userLogo = this.userInfo['user_organizacion']['logo'];
            this.publicationService.getUserOrganizacion(this.idUserInfo).subscribe((res) => {
              this.logo = res.logo;
            });
          }
          this.fetchPublication();
        },
        (err) => console.log(err)
      );
  }

  /**
   * Encuentra todos los datos de la publicacion y el comentario
   * */
  fetchPublication() {
    try {
      this.publicationService
        .getPublicationsFullData(this.userInfo.user._id)
        .toPromise()
        .then((result) => {
          console.log('this.publicaciones_ordenadas ', this.publicaciones_ordenadas);
          /**Organiza las publicaciomes */
          this.publicaciones_ordenadas = result.sort((a: any, b: any) => {
            return <any>new Date(b.dataPublicacion.fecha) - <any>new Date(a.dataPublicacion.fecha);
          });
          /**Solo activa la adición de publicaciones al DOM si esta vacío el arreglo,
           * esto para evitar la recursión de onScrollDown dado que ambos metodos se llaman entre ellos*/
          if (this.publicaciones_mostradas == 0) {
            this.onScrollDown();
          }
        });
    } catch (error) {
      this.modalCarga?.close();
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
        // this.router.navigate(['/inicio']);
      };
    }
  }

  /**
   * Metodo para traer todos los likes realizados a los contenidos
   * Este se usará cono input para determinar los likes por publicaión
   */
  fetchLikes() {
    this.publicationService
      .getLikes()
      .toPromise()
      .then((result) => {
        this.likes = result;
      });
  }

  /**
   * Con este metodo determinamos lo likes por cada publicación
   * recibe como @param1 el ID de la publicación
   * recibe como @param2  un toDo que es la tarea que va a realizar el metodo
   * este puede ser (liked_by_user) que determina si el usuario logueado ya dió like a la publicación
   * o puedeser un (total_likes) donde la función retornará el total de likes de de esa publicación
   * o un (get_id) el cual retorna el ID de la publicación este se usa como input para el metodo deleteLike
   */
  findLikesByPublications(id: any, toDo: string) {
    if (toDo == 'liked_by_user') {
      const likesOfPublication = this.likes.filter(
        (item: any) => item.publicacion === id && item.usuario === this.userInfo.user._id
      );
      if (likesOfPublication.length >= 1) {
        return true;
      } else {
        return false;
      }
    } else if (toDo == 'total_likes') {
      const likesOfPublication = this.likes.filter((item: any) => item.publicacion === id).length;
      return likesOfPublication;
    } else if (toDo == 'get_id') {
      const likesOfPublication = this.likes.filter(
        (item: any) => item.publicacion === id && item.usuario === this.userInfo.user._id
      );
      return likesOfPublication[0]._id;
    }
  }

  /**
   * Metodo para postear like a una publicación
   */
  postLike(id: any, event: Event) {
    const like = this.form.value;
    like.publicacion = id;
    like.estado = true;
    /**Si el botón está habilitado dejá guardar el like */
    const target = event.target as HTMLButtonElement;
    if (target.disabled != true) {
      this.fetchLikes();
      this.fetchPublication();
      try {
        this.publicationService.postLike(like).subscribe(() => {
          /**Si se guarda el like deshabilita el boton */
          target.disabled = true;
        });
      } catch (error) {
        /**Si no se guarda el like, deja el boton habilitado */
        target.disabled = false;
      }
    }
    /**Se deshabilita el boton para evitar que de like mas de una vez */
    target.disabled = true;
  }

  /**
   * Metodo para eliminar un like a una publicación
   */
  deleteLike(id: any) {
    this.publicationService.deleteLike(id).subscribe(() => {
      this.fetchLikes();
      this.fetchPublication();
    });
  }

  /**
   * Metodo para dar un like a una publicación
   */
  postComment(id: any) {
    if (this.form.valid) {
      const commentt = this.form.value;
      commentt.publicacion = id;
      commentt.logo_autor = this.logo;
      commentt.autor = this.idUserInfo;
      try {
        this.publicationService.postComment(commentt).subscribe(
          (res) => {
            const modalRef = this.modalService.open(SimpleComponent);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
            modalRef.componentInstance.title = '¡Genial!';
            modalRef.componentInstance.msg = '¡Tu comentario ha sido publicado con éxito!';
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = () => {
              this.fetchPublication();
              window.location.reload();
            };
          },
          (err) => console.log('err', err)
        );
      } catch (error) {
        this.modalCarga?.close();
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {};
      }
    }
  }

  /**
   * Metodo para eliminar una publicación por su ID
   */
  eliminarPublicacion(publicacion: any) {
    try {
      this.publicationService.deleteMyPublication(publicacion._id).subscribe(() => {
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Genial!';
        modalRef.componentInstance.msg = '¡Tu publicación ha sido eliminada con éxito!';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          this.fetchPublication();
          //TODO: No actualiza el DOM temporalmente refrescamos la pagina
          window.location.reload();
        };
      });
    } catch (error) {
      this.modalCarga?.close();
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {};
    }
  }

  /**
   * Metodos para detectar se ha realizado scroll down y
   * se muestra boton para retornar a la primera publicacion
   */
  @HostListener('window:scroll')
  onWindowScrolled() {
    const yOffset = window.pageYOffset;
    const scrollTop = this.document.documentElement.scrollTop;
    this.show_button = (yOffset || scrollTop) > 500;
  }

  onScrollTop() {
    this.document.documentElement.scrollTop = 0;
  }

  /**
   * Infinite scroll, cada vez que llegue a la ultima publicación
   * cargará 10 publicaciones mas hasta completar el total de publicaciones
   */
  onScrollDown() {
    for (let index = 0; index < 10; index++) {
      if (this.publicaciones_mostradas + index < this.publicaciones_ordenadas.length) {
        this.publicaciones_scroll_infinito.push(this.publicaciones_ordenadas[this.publicaciones_mostradas + index]);
      }
    }
    this.publicaciones_mostradas = this.publicaciones_mostradas + 10;
    /**Filtra solo las publicaciones realizadas por el usuario logueado */
    this.publicaciones_scroll_infinito_propias = this.publicaciones_scroll_infinito.filter(
      (element: any) => element.dataAutor.correo == this.userInfo.user.correo
    );
    /**
     * El spinner no tiene funcionalidad solo es por UX
     * para que el usuario sepa que se cargaron mas publicaciones
     */
    this.show_spinner = true;
    setTimeout(() => {
      this.show_spinner = false;
    }, 4000);
    console.log('pub', this.publicaciones_scroll_infinito);
  }

  /**
   * Retorna el nombre o logo del autor de una publicación
   * Dado que se tienen 3 tipos de autores en tres objetos,
   * se usa esta función para filtrar de los tres objetos de autores
   * el que tiene data, es decir el que es el autor real los otros vendran vacios
   * @param data objeto con la información. del autor
   * @param tipo_consulta si se usa la función para retornar el nombre o el logo
   * @returns el nombre o logo del autor de la publicación
   */
  getDataAutor(data: any, tipo_consulta: string) {
    if (tipo_consulta == 'nombre_empresa') {
      if (data.dataEmpresaAutor.nombre.length > 0) {
        return data.dataEmpresaAutor.nombre[0];
      } else if (data.dataEmpresaAutorDistribuidor.nombre.length > 0) {
        return data.dataEmpresaAutorDistribuidor.nombre[0];
      } else if (data.dataEmpresaAutorOrganizacion?.nombre?.length > 0) {
        return data.dataEmpresaAutorOrganizacion.nombre[0];
      }
    } else if (tipo_consulta == 'logo_empresa') {
      if (data.dataEmpresaAutor.logo.length > 0) {
        return data.dataEmpresaAutor.logo[0];
      } else if (data.dataEmpresaAutorDistribuidor.logo.length > 0) {
        return data.dataEmpresaAutorDistribuidor.logo[0];
      } else if (data.dataEmpresaAutorOrganizacion?.logo?.length > 0) {
        return data.dataEmpresaAutorOrganizacion.logo[0];
      }
    }
    return '';
  }
}
