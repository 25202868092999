import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmacion',
  templateUrl: './confirmacion.component.html',
  styleUrls: ['./confirmacion.component.css'],
})
export class ConfirmacionComponent implements OnInit {
  @Input() msg = '';
  @Input() btn_msg_yes = '';
  @Input() btn_msg_no = '';
  @Input() callback_yes = () => {};
  @Input() callback_no = () => {};

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  onYes() {
    this.activeModal.close('Close click');
    this.callback_yes();
  }

  onNo() {
    this.activeModal.close('Close click');
    this.callback_no();
  }
}
